import { useContext } from "react";
import PlanContent from "../../components/OrderItems/PlanContent";
import DeviceWithVasContent from "../../components/OrderItems/DeviceWithVasContent";
import PlanDetail from "./PlanDetail";
import { useTranslation } from "react-i18next";
import { GetWord, formatQuoteProductForGA } from "../../utils/common";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { deleteCart } from "../../apis";
import styled from "styled-components";
import { breakpoint, pageMargin } from "../../utils/responsive";
import EmptyCart from "./EmptyCart";
import { appearGlobalError } from "../../requests/globalRequest";
import { GlobalContextStore } from "../../context/GlobalProvider";
import MyButton from "../../components/Button";
import { WindowSize } from "../../utils/responsive";
import SpecialOrder from "../profile/orderInfo/SpecialOrder";
import { gaContinueShopping, gaRemoveFromCart } from "../../utils/ga";
import { gtmContinueShopping, gtmRemoveFromCart } from "../../utils/gtm";

const OrderList = ({ data, refetch, deliveryFeeRequired, shippingFee, shippingName }) => {
  const { t } = useTranslation(["orderContentItem", "shoppingCart"]);
  const navigate = useNavigate();
  const { globalState, globalDispatch } = useContext(GlobalContextStore);

  const isMobile = WindowSize() === "mobile";

  const removeCartItemMutation = useMutation({
    mutationFn: (item) => deleteCart(item?._id),
    onSuccess: (data, variables) => {
      const gaPayload = formatQuoteProductForGA(variables);
      gaRemoveFromCart(gaPayload);
      gtmRemoveFromCart(gaPayload);
    },
    onError: (error) => {
      console.log('==error remove cart item', error);
      appearGlobalError(
        globalDispatch,
        error?.result?.code,
        error?.result?.errorMessage,
        { type: "fail" }
      )
    },
    onSettled: () => {
      refetch && refetch();
    }
  })

  const onEdit = (props) => {
    const { type, itemId, editId } = props;
    // console.log('==url', `/${type}/${itemId}?editId=${editId}`);
    navigate(`/${type}/${itemId}?editId=${editId}`);
  };

  const onDelete = async (item, index) => {
    // console.log('==onClick remove cart item', item)
    removeCartItemMutation.mutate({ ...item, sort: index });
  };

  const handleClickContinueShopping = () => {
    const gaPayload = data.map((item, index) => formatQuoteProductForGA({ ...item, sort: index }));
    gaContinueShopping(gaPayload);
    gtmContinueShopping(gaPayload);

    navigate("/");
  }

  if (data?.length === 0) return (<EmptyCart />)
  else return (
    <Container>
      {data &&
        data.map((item, idx) => {
          if (item.type === "plan") {
            return (
              <PlanContent
                key={`plan-${idx}`}
                data={item}
                idx={idx}
                renderDetail={<PlanDetail data={item} extraWidthForButton={true} />}
                onEdit={onEdit}
                onDelete={() => onDelete(item, idx)}
                iconNeedDotDecoration // TODO: use customize icon / default icon?
              />
            );
          }
          else if (item.type === "product") {
            return (
              <DeviceWithVasContent
                key={`deviceWithVas-${idx}`}
                data={item}
                idx={idx}
                onEdit={onEdit}
                onDelete={() => onDelete(item, idx)}
              />
            );
          }
          // else if (item.type === "delivery") {
          //   return (
          //     <SpecialOrder
          //       key={`delivery-${idx}`}
          //       type={"delivery"}
          //       data={{
          //         itemName: item.deliveryInfo._displayName,
          //         subtotal: item.discountedSubtotal,
          //         quantity: item.quantity,
          //       }}
          //     />
          //   )
          // }
          else return null;
        })
      }

      {
        (deliveryFeeRequired && shippingFee) && (
          <SpecialOrder
            key={`delivery`}
            type={"delivery"}
            data={{
              itemName: shippingName,
              subtotal: shippingFee,
              quantity: 1,
            }}
            extraWidthForButton={true}
          />
        )
      }

      <ActionButtonContainer>
        <MyButton
          onClick={handleClickContinueShopping}
          label="Continue"
          fullWidth={isMobile}
        >
          {t('shoppingCart:continueShopping')}
        </MyButton>
      </ActionButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;

  @media ${breakpoint.mobile} {
      margin: 0 30px 60px 30px;
  }
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media ${breakpoint.mobile} {
    justify-content: center;
  }
`;

export default OrderList;
