import MyTypography from "../Typography";
import theme from "../../theme";

const ErrorText = ({ children, style }) => {
  return (
    <MyTypography fontSize={12} color={theme.error2} style={{ marginTop: 15, marginBottom: 15, ...style }}>
      {children}
    </MyTypography>
  );
};

export default ErrorText;
