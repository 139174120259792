import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import CommonCheckbox from "./Common/CommonCheckbox"
import Typography from "./Typography";
import theme from "../theme";
import styled from "styled-components";
import { useField } from "formik";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const CheckboxGroup = (props) => {
  const {
    title = undefined,
    options = [],
    required,
    circleCheckbox = false,
    row,
    multiple = false,
    titleStyle = {},
    checkboxStyle = {},
    labelProps = {},
    data = [],
    onChange,
    disabled = false,
    error = false,
    helperText = "",
    optionsContainerStyle = {},
    id,
    viewOnly,
    showError = true,
    containerStyle = {},
  } = props;
  const { t } = useTranslation("error");
  const [field, meta, helpers] = useField(id);

  return (
    <Grid item container flexDirection={"column"} gap={2} style={{ padding: 0, ...containerStyle }} name={id}>
      {title && (
        <Grid item style={{ padding: 0 }}>
          <Typography
            style={{
              fontSize: "16px",
              color: theme.lightBlack,
              ...titleStyle,
            }}
          >
            {title || ""}
            {required && (
              <span style={{ color: theme.orange, marginLeft: "5px" }}>*</span>
            )}
          </Typography>
        </Grid>
      )}

      {viewOnly ? (
        <Typography style={{ fontSize: "18px", color: theme.lightBlack }}>
          {_.find(options, ["value", field.value])?.label}
        </Typography>
      ) : (
        <Grid
          item
          container
          direction={row ? "row" : "column"}
          flexWrap={"wrap"}
          columnGap={"30px"}
          style={{ padding: 0, ...optionsContainerStyle  }}
        >
          {options.map((item, index) => (
            <Grid item style={{ padding: 0 }}>
              <CommonCheckbox
                key={`checkbox-${title}-option-${index}`}
                label={
                  item._renderLabel ? item._renderLabel() : item.label || ""
                }
                typographyProps={labelProps}
                style={{ alignItems: "flex-start", ...checkboxStyle }}
                circle={circleCheckbox}
                onChange={(e) => {
                  let selected = "";
                  if (multiple) {
                    selected = e.target.checked
                      ? [...field?.value, item?.value]
                      : field?.value.filter((value) => value !== item.value);
                  } else {
                    selected = e.target.checked ? item?.value : "";
                  }
                  helpers.setValue(selected);
                }}
                checked={
                  multiple
                    ? field?.value && field?.value.includes(item?.value)
                    : field?.value === item?.value
                }
                disabled={disabled}
              />
            </Grid>
          ))}
        </Grid>
      )}

      {showError && (error || meta?.error) && (
        <Typography fontSize={12} color={theme.error2}>
          {t ? t(meta?.error) : meta?.error || helperText}
        </Typography>
      )}
    </Grid>
  );
};

CheckboxGroup.defaultProps = {
  options: [
    { value: "female", label: "Female" },
    { value: "male", label: "Male" },
    { value: "other", label: "Other" },
  ],
};

export default CheckboxGroup;
