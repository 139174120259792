import { useContext } from "react";
import { useMutation } from "@tanstack/react-query";

import CustomerInformation from "../form/CustomerInformation";
import PaymentMethod from "../form/PaymentMethod";
import SupportingDoc from "../form/SupportingDoc";
import PreviewTermAndCondition from "../form/PreviewTermAndCondition";
import DeliveryContactInformation from "../form/DeliveryContactInformation";
import MnpNumberPreivew from "../form/MnpNumberPreview";
import PreviewForm from "./PreviewForm";
import OrderItem from "../orderList/OrderItems";
import Coupon from "../Coupon";

import { GlobalContextStore } from "../../../context/GlobalProvider";
import { CheckoutFormContext } from "../context/CheckoutFormContext";
import { appearGlobalError } from "../../../requests/globalRequest";
import useCheckoutGlobalTnc from "../hooks/useCheckoutGlobalTnc";
import { processPayment, updateQuoteTnc } from "../../../apis";
import { formatQuoteProductForGA } from "../../../utils/common";
import { gaBeginCheckout } from "../../../utils/ga";
import { gtmBeginCheckout } from "../../../utils/gtm";

const PreviewOrder = ({
  viewOnly,
  setIsPreview,
}) => {
  const {
    quoteQuery: quoteData,
    isDeviceOnly,
    hasDevice,
    configQuery,
    setStep,
  } = useContext(CheckoutFormContext)

  const { globalDispatch } = useContext(GlobalContextStore);
  const { acceptedTNCStatus, setAcceptedTNCStatus } = useCheckoutGlobalTnc();
  const { data={} } = quoteData?.data || {};
  const totalTncCount = data?.allProductOverallTncs?.length + data?.allPlanOverallTncs?.length;

  const handleMutationError = (e) => {
    appearGlobalError(
      globalDispatch,
      e?.result?.code,
      e?.result?.errorMessage,
      { type: "fail" },
    )
  }

  const processPaymentMutation = useMutation({
    mutationFn: () => {
      const urlBase = window.location.origin;
      return processPayment({
        redirectUrl: `${urlBase}/processingPayment`,
      })
    },
    onSuccess: (result) => {
      if (result && result?.data?.redirect_url) {
        window.open(result?.data?.redirect_url, '_self');
      } else throw result;
    },
    onError: handleMutationError,
  })

  const updateQuoteTncMutation = useMutation({
    mutationFn: () => updateQuoteTnc(acceptedTNCStatus),
    onSuccess: (result) => {
      if (result && result?.msg === "success") {
        // GA handling
        const gaPayload = data?.items.map((item, index) => (
          formatQuoteProductForGA({ ...item, sort: index })
        ));
        gaBeginCheckout(gaPayload, data?.promoCode);
        gtmBeginCheckout(gaPayload, data?.promoCode);
      } else throw result;
    },
    onError: handleMutationError,
  })

  const handleBack = () => {
    // update step status
    setStep && setStep((prev) => {
      return prev.map((item, index) => (
        { ...item, isActive: index === prev.length - 1 }
      ));
    });

    setIsPreview && setIsPreview(false);
  }

  const handleSubmitQuote = () => {
    console.log('update quote tnc');
    updateQuoteTncMutation.mutateAsync()
      .then(() => {
        console.log('process payment');
        processPaymentMutation.mutate()
      })
  }

  return (
    <PreviewForm
      data={data}
      onBack={handleBack}
      onSubmit={handleSubmitQuote}
      isLoading={processPaymentMutation.isLoading || updateQuoteTncMutation.isLoading}
    >
      <OrderItem viewOnly={viewOnly} />
      {data?.promoCode && <Coupon data={data} isViewOnly={viewOnly} />}
      <MnpNumberPreivew data={data} />
      <CustomerInformation viewOnly={viewOnly} />
      <DeliveryContactInformation
        viewOnly={viewOnly}
        allowDelivery={data?.isAllowedDelivery}
        allowPickup={data?.isAllowedPickup}
        configData={configQuery?.data?.data}
        hasDevice={hasDevice}
      />
      <PaymentMethod viewOnly={viewOnly} hasPlan={!isDeviceOnly} />
      { !isDeviceOnly && <SupportingDoc /> }
      {totalTncCount > 0 && <PreviewTermAndCondition
        acceptedTNC={acceptedTNCStatus}
        setAcceptedTNC={setAcceptedTNCStatus}
      />}
    </PreviewForm>
  );
};
export default PreviewOrder;
