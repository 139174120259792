import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { useMutation, useQuery } from '@tanstack/react-query';

import { CircularProgress, Grid } from '@mui/material';
import SimpleDialog from '../../../../components/CommonDialog/SimpleDialog';
import YellowBubbleCheckbox from '../../../../components/Checkboxs/YellowBubbleCheckbox';
import MyTypography from '../../../../components/Typography';
import CommonTextField from '../../../../components/Common/CommonTextField';
import MyButton from '../../../../components/Button';
import Space from '../../../../components/Space';
import IconRefresh from '../../../../assets/ic-refresh.svg';

import { GlobalContextStore } from '../../../../context/GlobalProvider';
import { appearGlobalError } from '../../../../requests/globalRequest';
import { WindowSize } from '../../../../utils/responsive';
import { gaSelectNewNumber } from '../../../../utils/ga';
import { gtmSelectNewNumber } from '../../../../utils/gtm';
import { fetchLuckyNumber, holdNewNumber, searchLuckyNumber } from '../../../../apis';
import theme from '../../../../theme';
import LoadingIndicator from '../../../../components/LoadingIndicator';

const NewNumberDialog = ({ isOpen=false, setIsOpen, name }) => {

  const { t } = useTranslation(['checkout', 'common']);
  const { globalDispatch } = useContext(GlobalContextStore);
  const [field, meta, helper] = useField(name);

  const [keyword, setKeyword] = useState();
  const [tempNumList, setTempNumList] = useState([]);
  const [selectedNumList, setSelectedNumList] = useState([]);
  const [error, setError] = useState(false);

  const isMobile = WindowSize() === 'mobile';
  const orderId = field.value?._id

  const newNumberQuery = useQuery({
    queryKey: ['fetchLuckyNumber', keyword],
    queryFn: () => {
      if (keyword && keyword.length >= 2) {
        return searchLuckyNumber({ number: keyword })
      } else if (!keyword) {
        return fetchLuckyNumber();
      };
    },
    onSuccess: (result) => {
      const filterData = selectedNumList.length > 0 ? result.filter(
        (item) => !selectedNumList.find((selected) => selected._id === item._id)
      ) : result;

      setTempNumList([
        ...selectedNumList,
        ...(selectedNumList.length > 0 ? filterData.slice(0, filterData.length - selectedNumList.length) : filterData),
      ]);

      return result?.data;
    },
    refetchOnWindowFocus: false,
    enabled: isOpen,
  });

  const holdNumberMutation = useMutation({
    mutationFn: (body) => holdNewNumber(body, orderId),
    onSuccess: (result) => {
      if (result?.msg === 'success') {
        helper.setValue({
          ...field.value,
          order_newNumList: selectedNumList,
        });

        // GA event
        gaSelectNewNumber(selectedNumList.map((item) => item.number).join(","));
        gtmSelectNewNumber(selectedNumList.map((item) => item.number).join(","));

        setIsOpen(false);
        setError(false);
      } else throw result;
    },
    onError: (e) => {
      appearGlobalError(globalDispatch, e?.result?.code, e?.result?.errorMessage, {
        type: 'fail',
      });
    },
  })

  const isNoResult = newNumberQuery.isSuccess && newNumberQuery.data?.data?.length < 1;
  const isLoading = newNumberQuery.isLoading || holdNumberMutation.isLoading;

  useEffect(() => {
    if (isOpen && field?.value?.order_newNumList) {
      setSelectedNumList(field?.value?.order_newNumList);
    }
  }, [isOpen]);

  const handleSearch = async (e) => {
    const val = e.target.value;
    const reg = new RegExp(/^\d{0,8}$/);

    if (val === '') {
      setKeyword('');
    } else if (reg.test(val)) {
      setKeyword(val);
    }
  };

  const handleRefreshSearch = async (e) => {
    setKeyword('')
    newNumberQuery.refetch()
  }

  const handleSelect = (num) => {
    setSelectedNumList([...selectedNumList, num]);
  };

  const handleDelete = (num) => {
    setSelectedNumList(selectedNumList.filter((val) => val !== num));
  };

  const handleSave = () => {
    if (selectedNumList.length === field.value.order_newNumQty) {
      const body = { newNumberPoolIds: selectedNumList.map((item) => item._id) };
      holdNumberMutation.mutate(body);
    } else {
      setError(true);

      const element = document.getElementById('newNumberDialog');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <SimpleDialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={t('changeNumber')}
      hasSpace="false"
    //containerStyle={{ display: 'flex', flexDirection: 'column' }}
    >
      <Grid
        container
        item
        direction="column"
        wrap="nowrap"
        height={"95%"}
        rowSpacing={2}
        id="newNumberDialog"
        // pt={4}
      >
        <Grid
          item
          spacing={2}
          className="tmp-section-selectedNumber"
          marginTop="20"
          style={{ padding: 10, margin: -10 }} //box-shadow disappear with overflow https://stackoverflow.com/a/41503444
        >
          <Grid container item spacing={2} pt={4}>
            {selectedNumList.map((num) => {
              const formattedLabel = num?.number
                ?.toString()
                .replace(/(\d{4})/g, '$1 ');
              return (
                <Grid item xs={isMobile ? 12 : 6}>
                  <YellowBubbleCheckbox
                    fullWidth
                    label={formattedLabel}
                    selected
                    checked={true}
                    onChange={(e) => handleDelete(num)}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item>
          {error && (
            <MyTypography color="error">
              {t('warnPleaseChooseNumber', {
                num: field.value.order_newNumQty,
              })}
            </MyTypography>
          )}
        </Grid>
        <Grid item mb={3}>
          <CommonTextField
            placeholder={t('checkout:newNumberDialog.placeholder')}
            value={keyword}
            handleChange={(e) => handleSearch(e)}
            inputProps={{
              style: {
                padding: isMobile ? "9px" : undefined,
                roundCorner: isMobile ? "12px" : undefined,
              },
            }}
          />
        </Grid>
        <Grid
          item
          rowSpacing={2}
          className="tmp-selection-allNumbersInPool"
          style={{ flex: 1, padding: 10, margin: -10, display: 'flex', flexDirection: 'column', flexWrap: 'nowrap' }}

        >
          <Grid item pb={2} >
            <MyTypography fontWeight="500" fontSize={18} mFontSize={16} >
              {t('checkout:newNumberDialog.title')}
            </MyTypography>
            {isNoResult && (
              <MyTypography color="error">{t('noMatchingResult')}</MyTypography>
            )}
          </Grid>
          <Grid container item spacing={2} minHeight={200} style={{ flex: 1 }}>
            {tempNumList?.length > 0 ? tempNumList.map((num) => {
              const formattedLabel = num?.number
                ?.toString()
                .replace(/(\d{4})/g, '$1 ');
              return (
                <Grid item xs={isMobile ? 12 : 6} key={formattedLabel}>
                  <YellowBubbleCheckbox
                    fullWidth
                    label={formattedLabel}
                    onChange={() => handleSelect(num)}
                    checked={selectedNumList.indexOf(num) > -1}
                    disabled={selectedNumList.indexOf(num) > -1}
                  />
                </Grid>
              );
            }) : (
              isLoading ? <Grid item xs><LoadingIndicator containerStyle={{ height: "100%" }} /></Grid> : null
            )}
            <Space size="20px" width="1px" />
          </Grid>
        </Grid>
        <Grid container gap={2} direction="column" alignItems="center" style={{ marginTop: 20 }}>
          <MyButton 
            style={{ width: isMobile ? '100%' : '50%', color: 'black', boxShadow: '0 3px 10px #c9c9c9', minWidth: 'fit-content' }}
            color="white"
            onClick={handleRefreshSearch}
            disabled={isLoading}
          >
            <img src={IconRefresh} alt="refresh" style={{ marginRight: 20, height: '1em' }} />
            {t('refreshNumber')}
          </MyButton>

          <MyButton onClick={handleSave} style={{ width: isMobile ? '100%' : '50%' }} disabled={holdNumberMutation.isLoading}>
            {t('common:save')}
          </MyButton>
        </Grid>
      </Grid>
    </SimpleDialog>
  );
};
export default NewNumberDialog;
