import { useContext, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";

import LoadingIndicator from "../components/LoadingIndicator";
import HomeContent from "../pageComponents/home/HomeContent";

import { GlobalContextStore } from "../context/GlobalProvider";
import { fetchHomeBannerList } from "../apis";

const Home = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const { globalState, isApiAccessible } = useContext(GlobalContextStore);
  const companyId = globalState?.userInfo?.company?._id;

  const bannerData = useQuery({
    queryKey: ["homeBanners", companyId],
    queryFn: () => {
      const company = globalState?.userInfo?.company?.companyType === "vip" 
                        ? companyId 
                        : undefined;
      return fetchHomeBannerList(company);
    },
    enabled: isApiAccessible,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: false,
  });

  // redirect to contact us for non-whitelist user after login
  useEffect(() => {
    const isAuth = !isEmpty(globalState?.userInfo);

    if (isAuth && location?.state?.from === "/login") {
      const isWhitelistUser = !isEmpty(globalState?.userInfo?.company);

      if (!isWhitelistUser) {
        navigate("/contactUs", { replace: true });
      }
    }
  }, [location?.state?.from, globalState?.userInfo, navigate]);

  if (bannerData?.isLoading) return (
    <LoadingIndicator />
  )

  return (
    <HomeContent bannerData={bannerData} companyId={companyId} />
  );
};

export default Home;
