import { useState } from 'react';
import { Grid } from "@mui/material";
import { useTranslation } from 'react-i18next';
import SimpleDialog from "../../../../components/CommonDialog/SimpleDialog";
import AlertDialog from '../../../../components/CommonDialog/AlertDialog';
import InfoRecord from "./InfoRecord";
import EditContactForm from '../EditContactForm';

const InfoSection = (props) => {
    const { 
        type, 
        data, 
        containerStyle=null, 
        PADDING_VALUE="38px",
        onEdit=null,
        onDelete=null,
    } = props;

    const { t } = useTranslation(['profile', "common"]);

    const [dialogContent, setDialogContent] = useState(null);
    const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);

    const handleOnClickEdit = (item) => {
        setEditDialogIsOpen(true);
        setDialogContent(item);
    }

    const handleOnClickDelete = (item) => {
        setDeleteDialogIsOpen(true);
        setDialogContent(item);
    }

    const handleOnEdit = (payload) => {
        onEdit && onEdit(payload);
    }

    const handleOnDelete = (item) => {
        onDelete && onDelete(item);
    }

    const isClientInfo = type === 'clientInfo';

    return (
        <>
        {/* Info Section */}
        <Grid item container style={{ padding: `0 ${PADDING_VALUE}`, ...containerStyle }}>
            { data.map((item, index) => (
                <InfoRecord 
                    key={`${type}-${index}`} 
                    type={type}
                    data={item} 
                    onClickEdit={onEdit ? () => handleOnClickEdit(item) : null}
                    onClickDelete={onDelete ? () => handleOnClickDelete(item) : null}
                />
            ))}
        </Grid>

        {/* Edit Info Record Dialog */}
        <SimpleDialog 
            title={t('accountProfile.editContactDialogTitle')}
            isOpen={editDialogIsOpen}
            setIsOpen={setEditDialogIsOpen}
            children={<EditContactForm data={dialogContent} setDialogIsOpen={setEditDialogIsOpen} onSubmit={handleOnEdit} />}
        />

        {/* Delete Info Record Dialog */}
        <AlertDialog
            title={t('accountProfile.deleteAddressDialogTitle')}
            isOpen={deleteDialogIsOpen}
            setIsOpen={setDeleteDialogIsOpen}
            type="warning"
            content={t('accountProfile.cannotRecoverAfterDelete')}
            btnLabel={t('common:confirm')}
            handleClose={() => setDeleteDialogIsOpen(false)}
            onNext={() => handleOnDelete(dialogContent)}
        />
        </>
    )
}

export default InfoSection;