import styled from 'styled-components';
import AnimatedCard from '../AnimatedCard';
import BasicPlanInfo from './BasicPlanInfo';
import Button from '../Button';
import TextButton from '../TextButton';
import CardTag from '../CardTag';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import AdbIcon from '@mui/icons-material/Adb';
import theme from '../../theme';
import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InfoButton from '../Plan/InfoButton';
import { GetWord } from '../../utils/common';
import { isEmpty } from 'lodash';
import { gtmViewDetails, gtmViewServicePlan } from '../../utils/gtm';
import { gaViewDetails, gaViewServicePlan } from '../../utils/ga';

const StyledList = styled(List)`
  padding: 20px !important;
  padding-left: 28px !important;
  flex: 1;
  min-height: 85px;
`;

const StyledListItemText = styled(ListItemText)`
  font-size: ${(props) => (props?.smallSize ? 16 : 18)}px;
  font-weight: ${(props) => (props?.smallSize ? undefined : 500)};
  color: #333;

  ${(props) => props?.wrapTextAfterLine && `overflow: hidden;`}
  ${(props) => props?.wrapTextAfterLine && `text-overflow: ellipsis;`}
  ${(props) => props?.wrapTextAfterLine && `display: -webkit-box;`}
  ${(props) =>
    props?.wrapTextAfterLine &&
    `-webkit-line-clamp: ${props?.wrapTextAfterLine};`}
  ${(props) => props?.wrapTextAfterLine && `-webkit-box-orient: vertical;`}
`;

const StyledImageIcon = styled.img`
  width: ${(props) => (props?.smallSize ? 20 : 40)}px;
  height: ${(props) => (props?.smallSize ? 20 : 40)}px;
  border-radius: ${(props) => (props?.smallSize ? 6 : 12)}px;
`;

export const FeatureListItem = ({
  icon,
  title,
  description,
  remark,
  smallSize = false,
  remarkIconSize,
  wrapTextAfterLine,
}) => {
  const iconSize = smallSize ? 20 : 40;

  if (isEmpty(icon) || isEmpty(description?.en) || isEmpty(description?.zh))
    return null;

  return (
    <ListItem
      style={{ padding: smallSize ? 0 : '8px 0', alignItems: 'flex-start' }}
    >
      <ListItemAvatar style={{ minWidth: iconSize + 12 }}>
        <div
          style={{
            width: iconSize,
            height: iconSize,
            display: 'flex',
            marginTop: 5,
          }}
        >
          <StyledImageIcon smallSize={smallSize} src={icon} />
        </div>
      </ListItemAvatar>
      <StyledListItemText
        disableTypography
        smallSize={smallSize}
        wrapTextAfterLine={wrapTextAfterLine}
      >
        {GetWord(description)}
      </StyledListItemText>
      {remark && (
        <InfoButton
          title={{ en: 'Remark', zh: '備註' }}
          content={remark}
          size={remarkIconSize}
          style={{ marginLeft: '5px' }}
        />
      )}
    </ListItem>
  );
};

const PlanCard = ({ type, handleClick, data, isRecommend=false, ...props }) => {
  const { t, i18n } = useTranslation(['home', 'common']);

  const StyledTextButton = ({ flip, label }) => {
    return (
      <TextButton
        textColor={theme.dirtyYellow}
        fontSize={12}
        style={{
          width: 'fit-content',
          alignSelf: 'center',
          padding: '6px 8px 10px 8px',
        }}
        onClick={() => flip && flip()}
      >
        {label}
      </TextButton>
    );
  };

  return (
    <AnimatedCard
      {...props}
      tag={data?.promotionTitle}
      onFlip={(active) => {
        if (active) {
          // trigger GA event when flip to back
          const planName = GetWord(data?.planName);
          
          if (isRecommend) {
            gaViewDetails(planName);
            gtmViewDetails(planName);
          } else {
            gaViewServicePlan(planName);
            gtmViewServicePlan(planName);
          }
        }
      }}
      renderFront={({ flip }) => {
        return (
          <>
            <BasicPlanInfo
              yellowTag={data?.flipCardLabel}
              icon={data?.flipCardIconImageUrl}
              title={data?.planName}
              subTitle={data?.localData}
              originalPrice={data?.originalPrice}
              discountedPrice={data?.discountedPrice}
              quantityDesc={t('common:month')}
            />
            <Divider />
            <StyledList>
              {data &&
                data?.cardFront.map((item) => {
                  return (
                    <FeatureListItem
                      icon={item.imageUrl}
                      description={item.description}
                      smallSize
                      wrapTextAfterLine={1}
                    />
                  );
                })}
            </StyledList>
            <div style={{ flex: 1 }} />
            <Button
              color="yellow"
              style={{ margin: `0px 20px`, height: '42px', fontSize: 16 }}
              onClick={handleClick}
            >
              {t('selectPlan')}
            </Button>
            <StyledTextButton label={t('viewPlan')} flip={flip} />
          </>
        );
      }}
      renderBack={({ flip }) => {
        return (
          <>
            <CardTag>{t('mobilePhonePlan')}</CardTag>
            <StyledList>
              {data &&
                data?.cardBack.map((item) => {
                  return (
                    <FeatureListItem
                      smallSize
                      icon={item.imageUrl}
                      description={item.description}
                    />
                  );
                })}
            </StyledList>
            <StyledTextButton label={t('hidePlan')} flip={flip} />
          </>
        );
      }}
    />
  );
};

export default PlanCard;
