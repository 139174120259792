import { useContext, useEffect, useMemo, useState } from 'react';
import _, { cloneDeep, merge } from 'lodash';
import { uniqBy } from 'lodash';

/**
 * @readonly
 * @enum {string}
 */
export const ACCEPTED_TNCS_KEYS = {
  savePersonalInformation: 'savePersonalInformation',
  directSaleThisServices: 'directSaleThisServices',
  directSaleMyClubHKT: 'directSaleMyClubHKT',
  productTncs: 'productTncs',
  planTncs: 'planTncs',
  overallTncs: 'overallTncs',
};

/**
 * hooks to determine the initial required tncs on start of each checkout
 * because in previous implementation, tncs can be dynamic added when filling the form
 * @param {*} isDeviceOnly
 * @param {*} quoteQuery
 * @param {*} tncQuery
 * @returns
 */
const useCheckoutInitialRequiredTncs = (isDeviceOnly, quoteQuery, tncQuery) => {
  const TNC_KEYPATH = [
    'tnc.savePersonalInformation',
    'tnc.directSaleThisServices',
    'tnc.directSaleMyClubHKT',
  ];

  /**
   * @type {import('../../../apis').AcceptedTncStatusPayload}
   * 
   * @property {AcceptedTncStatusValue2[]} savePersonalInformation
   */
  const initialObject = {
    // status is inserted on each time api update
    [ACCEPTED_TNCS_KEYS.savePersonalInformation]: [],
    [ACCEPTED_TNCS_KEYS.directSaleThisServices]: [],
    [ACCEPTED_TNCS_KEYS.directSaleMyClubHKT]: [],
    // update on checked
    [ACCEPTED_TNCS_KEYS.productTncs]: [],
    // update on both cases, that cause overwrite
    [ACCEPTED_TNCS_KEYS.planTncs]: [],
    [ACCEPTED_TNCS_KEYS.overallTncs]: [],
  };

  const [acceptedTNCStatus, setAcceptedTNCStatus] = useState(initialObject);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    // initialize once
    if (
      tncQuery?.data?.data &&
      quoteQuery?.data?.data &&
      !initialized &&
      isDeviceOnly !== undefined
    ) {
      console.log('init tncs by state');
      setAcceptedTNCStatus(
        isDeviceOnly ? getDeviceOnlyTncs() : getWithPlanInitialTncs()
      );
      setInitialized(true);
    }
  }, [quoteQuery, tncQuery, initialized, isDeviceOnly]);

  const mappedNormalTncs = merge(
    initialObject,
    _.chain(tncQuery?.data?.data)
      .filter((item) => TNC_KEYPATH.includes(item.keyPath))
      .filter((item) => !_.isEmpty(item?.value))
      .groupBy('keyPath')
      .mapKeys((value, key) => key.replace('tnc.', ''))
      .mapValues((value) =>
        value.map((item) => ({ tncId: item?.value?._id, accepted: false }))
      )
      .value()
  );

  const productOverallTncsInitialValue = quoteQuery?.data?.data?.allProductOverallTncs?.map(
    (item) => {
      return { tncId: item._id, accepted: false };
    }
  ) || [];

  const planOverallTncsInitialValue = quoteQuery?.data?.data?.allPlanOverallTncs?.map(
    (item) => {
      return { tncId: item._id, accepted: false };
    }
  ) || [];

  const overallTncsInitialValue = uniqBy(
    [...productOverallTncsInitialValue, ...planOverallTncsInitialValue],
    (tnc) => tnc.tncId
  )

  const getWithPlanInitialTncs = () => {
    // source: quote + tnc
    const step1PlanTnc =
      tncQuery?.data?.data
        ?.find((item) => item.keyPath === 'tnc.planBeforeBuy')
        ?.value.map((item) => ({ tncId: item._id, accepted: false })) || [];

    const allTncRequired = {
      ...mappedNormalTncs,
      productTncs: productOverallTncsInitialValue,
      planTncs: step1PlanTnc,
      overallTncs: overallTncsInitialValue,
    };

    //return concatLocalRequiredTncWithRemote(allTncRequired)
    return allTncRequired;
  };

  const getDeviceOnlyTncs = () => {
    const tncs = {
      ...mappedNormalTncs,
      productTncs: productOverallTncsInitialValue,
      overallTncs: overallTncsInitialValue,
    };

    //return concatLocalRequiredTncWithRemote(tncs)
    return tncs;
  };

  return {
    acceptedTNCStatus,
    setAcceptedTNCStatus,
    // add source of tnc view here
  };
};

export default useCheckoutInitialRequiredTncs;
