import { useTranslation } from "react-i18next";
import styled from "styled-components";
import SimpleDialog from "../../../components/CommonDialog/SimpleDialog";
import Button from "../../../components/Button";
import ShoppingBagZh from "../../../assets/ic-1010ShoppingBag-zh.svg";
import ShoppingBagEn from "../../../assets/ic-1010ShoppingBag-en.svg";
import { GetWord } from "../../../utils/common";
import { breakpoint, WindowSize } from "../../../utils/responsive";
import theme from "../../../theme";
import { useGreetingDialog } from "../../../hooks/useGreetingDialog";

import GreetingDialogTitle from "./Title";

const GreetingDialog = (props) => {
    const {
        isDialogOpen: isOpen,
        handleClickLogin: onClickButton,
        handleCloseDialog: onClose,
    } = useGreetingDialog();

    const { t } = useTranslation(["home", "common"]);

    const isMobile = WindowSize() === "mobile";

    return (
        <SimpleDialog
            isOpen={isOpen}
            handleClose={() => onClose && onClose()}
            titleProp={<GreetingDialogTitle />}
            containerStyle={isMobile ? { padding: "0 30px 36px 30px", marginTop: "36px" } : undefined}
        >
            <ContentContainer>
                <div>
                    <img src={GetWord({ zh: ShoppingBagZh, en: ShoppingBagEn })} alt="Welcome" style={{ width: 'auto', height: '100px' }} />
                </div>
                <StyledButton onClick={() => onClickButton && onClickButton()} fullWidth>{t('common:pageTitle.login')}</StyledButton>
            </ContentContainer>
        </SimpleDialog>
    )
}

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;

    @media ${breakpoint.mobile} {
        gap: 60px;
    }
`

const StyledButton = styled(Button)`
    color: ${theme.lightBlack} !important;
    background: ${theme.yellow} !important;

    @media ${breakpoint.desktop} {
        max-width: 300px;
    }
`;

export default GreetingDialog;