import { useState, useEffect } from "react";
import styled from "styled-components";
import CardBase from "../../components/Card/CardBase";
import MyTypography from "../../components/Typography";
import MyTextField from "../../components/TextField";
import Space from "../../components/Space";
import MyButton from "../../components/Button";
import { useTranslation } from "react-i18next";
import theme from "../../theme";
import { updateCoupon } from "../../apis";
import { breakpoint } from "../../utils/responsive";
import { useFormikContext, useField } from "formik";
import { WindowSize } from "../../utils/responsive";
import { GetWord } from "../../utils/common";
import SpecialOrder from "../profile/orderInfo/SpecialOrder";
import { gaApplyPromoCode } from "../../utils/ga";
import { gtmApplyPromoCode } from "../../utils/gtm";
import { useMutation } from "@tanstack/react-query";

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 30px 40px;
  gap: 50px;
  justify-content: start;
  flex-wrap: wrap;

  @media ${breakpoint.mobile} {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    padding: 30px 25px;
  }
`;

const CouponFieldContainer = styled.div`
  display: flex;
  align-items: center;

  @media ${breakpoint.mobile} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TNCContainer = styled.div`
  flex-basis: 220px;
   @media ${breakpoint.mobile} {
    flex-basis: 0;
   }
  flex-grow: 1;
`

const Coupon = ({ ...props }) => {
  const {
    data = null,
    refetch,
    isViewOnly = false,
  } = props;

  const [field, meta, helpers] = useField("coupon_code");
  const { t } = useTranslation("checkout");
  const isMobile = WindowSize() === "mobile";

  const updateCouponMutation = useMutation({
    mutationFn: (promoCode) => updateCoupon({ promoCode }),
    onSuccess: (result, promoCode) => {
      if (result && result?.msg === 'success') {
        if (promoCode !== "") {
          gaApplyPromoCode(promoCode);
          gtmApplyPromoCode(promoCode);
        }
        refetch && refetch();
      } else throw result;
    },
    onError: (err) => {
      helpers.setError(err?.result?.errorMessage)
      const couponField = document.getElementsByName("coupon_code")[0];
      couponField.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  })

  const handleOnClickApply = () => {
    const couponValue = field.value.replace(" ", "");
    if (couponValue === "") return;

    updateCouponMutation.mutate(couponValue);
  }

  const handleOnClickRemove = () => {
    updateCouponMutation.mutate("");
  }

  if (data && data?.promoCode) return (
    <SpecialOrder
      type={"discount"}
      data={{
        itemName: { en: data?.promoCode, zh: data?.promoCode },
        description: data?.promoCodeName || null,
        subtotal: -data?.promoCodeDiscount,
        quantity: 1,
      }}
      onDelete={!isViewOnly ? handleOnClickRemove : undefined}
      extraWidthForButton={!isViewOnly}
      cardBaseStyle={{ marginTop: 0 }}
    />
  )

  return (
    <CardBase style={{ marginTop: 0 }}>
      <Container>
        <CouponFieldContainer>
          <MyTypography fontSize="24" bold style={{ whiteSpace: "nowrap" }}>{t('coupon')}</MyTypography>
          <Space width="50px" size={isMobile ? "25px" : undefined} />
          <MyTextField
            id="coupon_code"
            width={isMobile ? "100%" : "250px"}
            containerStyle={{ flex: 1, maxWidth: isMobile ? undefined : 250, width: "100%" }}
            placeholder={t('coupon')}
            helperText={GetWord(meta.error)}
            onChange={(e) => {
              helpers.setError(null)
              helpers.setValue(e.target.value)
            }}
          />
          <Space width="30px" size={isMobile ? "25px" : undefined} />
          <MyButton onClick={handleOnClickApply} style={{ alignSelf: isMobile ? "flex-end" : "start" }} fullWidth={isMobile} disabled={updateCouponMutation.isLoading}>
            {t('applyCouponBtn')}
          </MyButton>
        </CouponFieldContainer>
        <TNCContainer>
          <MyTypography fontSize="13" color={theme.dirtyYellow}>{t('couponTNC')}</MyTypography>
        </TNCContainer>
      </Container>
    </CardBase>
  );
};

export default Coupon;
