import styled from "styled-components";
import { useFormik, FormikProvider, Form } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import MyTextField from "../../components/TextField";
import MyButton from "../../components/Button";
import Space from "../../components/Space";
import MyTypography from "../../components/Typography";
import theme from "../../theme";
import { ReactComponent as RemarkIcon } from "../../assets/ic-remark.svg";
import RemarkIconSource from "../../assets/ic-remark.svg";

const CheckEmail = ({ handleLogin, isLoading=false }) => {
  const { t } = useTranslation(["login", "error"]);

  const formik = useFormik({
    initialValues: { email: "" },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      email: Yup.string().email("login:errorInvalidEmail").required("error:required"),
    }),
    onSubmit: (payload) => {
      console.log("submit login", payload);
      handleLogin(payload.email)
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <MyTextField
          id="email"
          placeholder={"chantaiman@abc.com.hk"}
          title={t("email")}
        />

        <Space size="20px" />
        <MyTypography style={{ lineHeight: 1.3 }}>
          {t('mustUseCompanyDomainEmail')}
        </MyTypography>
        <Space size="20px" />
        <div
          style={{ display: "flex", alignItems: "start" }}
        >
          {/* <RemarkIcon  /> */}
          <img src={RemarkIconSource} alt="remark" width={15} style={{ marginTop: '3px'}} />
          <Space width="20px" />
          <MyTypography fontSize={14} color={theme.dirtyYellow} style={{ lineHeight: 1.3 }}>
            {t("error_email")}
          </MyTypography>
        </div>

        <Space size="30px" />
        <MyButton onClick={() => formik.handleSubmit()} fullWidth disabled={isLoading}>
          {t("continue")}
        </MyButton>
      </Form>
    </FormikProvider>
  );
};

export default CheckEmail;
