import { useState, useEffect, forwardRef, useMemo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import { Divider } from "@mui/material";
import CardBase from "../../components/Card/CardBase";
import MyTypography from "../../components/Typography";
import Checkbox from "../../components/Common/CommonCheckbox";

import StepContainer from "./StepContainer";
import TNCItem from "./tnc/TncItem";

import theme from "../../theme";
import { ResponsiveUI, breakpoint } from "../../utils/responsive";
import { ACCEPTED_TNCS_KEYS } from "./hooks/useCheckoutInitialRequiredTncs";

const TNC = forwardRef(({
  step,
  setStep,
  currentStep,
  tncData,
  acceptedTNC,
  setAcceptedTNC,
  onSave,
  onNext,
  ...props
}, ref) => {

  const { t } = useTranslation(["checkout", "error"]);

  const [acceptedAllTNC, setAcceptedAllTNC] = useState(false);
  const [error, setError] = useState(false);

  const data = useMemo(() => (
    tncData?.find((item) => item.keyPath === "tnc.planBeforeBuy")?.value || []
  ), [tncData]);

  // auto check "I agree all TNC" if all TNC are accepted
  useEffect(() => {
    const allAccepted = acceptedTNC?.planTncs.length > 0
                          && _.findIndex(acceptedTNC.planTncs, ["accepted", false]) === -1;

    setAcceptedAllTNC(allAccepted);
  }, [acceptedTNC])

  const handleAcceptAllTnc = () => {
    const temp =
      data &&
      data?.map((item) => {
        return { tncId: item._id, accepted: !acceptedAllTNC };
      });

    setAcceptedTNC((prev) => ({ ...prev, planTncs: temp }));
    setAcceptedAllTNC(!acceptedAllTNC);
  };

  const handleSave = (toNext=false) => {
    if (
      data?.length > 0 &&
      (_.isEmpty(acceptedTNC.planTncs) ||
        _.findIndex(acceptedTNC.planTncs, ["accepted", false]) > -1)
    ) {
      setError(true);
      const element = document.getElementById("tnc");
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      setError(false);
      toNext && onNext && onNext();
    }
  };

  return (
    // onSave and onClick
    <StepContainer
      step={step}
      currentStep={currentStep}
      setStep={setStep}
      title={t("confirmTermsAndConditions")}
      onSave={() => handleSave(false)}
      onProceedNextStep={() => handleSave(true)}
      ref={ref}
      {...props}
    >
      <CardBase>
        {/* Title */}
        <Container>
          <MyTypography fontSize="24" bold>
            {t("termsAndConditions")}
          </MyTypography>
        </Container>

        <Divider />

        {/* TNC List */}
        <TncContainer>
          { data.map((item) => (
            <>
              <TNCItem
                id={item?._id}
                keyPath={ACCEPTED_TNCS_KEYS.planTncs}
                title={item?.title}
                shortDescription={item?.shortDescription}
                detail={item?.description}
                acceptedTNC={acceptedTNC}
                setAcceptedTNC={setAcceptedTNC}
                disabled={acceptedAllTNC}
                disableDivider={true}
              />
              <Divider />
            </>
          ))}
        </TncContainer>

        {/* Accept All TNC */}
        <Container id="tnc">
          <Checkbox
            checked={acceptedAllTNC}
            label={t("iAgreeAllTnc")}
            onClick={handleAcceptAllTnc}
            checkboxStyle={{ paddingLeft: 0 }}
          />
          {error && (
            <MyTypography fontSize={12} color={theme.error2} style={{ marginTop: 15 }}>
              {t("error:requiredAcceptTnc")}
            </MyTypography>
          )}
        </Container>
      </CardBase>
    </StepContainer>
  );
});

const Container = styled.div`
  padding: 30px;

  @media ${breakpoint.mobile} {
    padding: 30px 25px;
  }
`;

const TncContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export default TNC;
