import { forwardRef, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFormik, Form, FormikProvider, validateYupSchema, yupToFormErrors } from "formik";
import dayjs from "dayjs";
import * as Yup from "yup";
import _ from "lodash";

import StepContainer from "../StepContainer";
import { GlobalContextStore } from "../../../context/GlobalProvider";
import {
  getMobileNumYUP,
  getOnlyChineseEnglishStringYUP,
  getOnlyChineseStringYUP,
  getOnlyEnglishStringYUP,
} from "../../../utils/validate";
import { useLocalStorageFormData } from "../../../hooks/useLocalStorageFormData";
import useCheckoutGlobalTnc from "../hooks/useCheckoutGlobalTnc";

const getInitialValues = (data, user=null) => {
  const { userInfo, contactUserInfo, deliveryInfo } = data?.userForm;

  const contactInfoIsSameAsCustomerInfo =
    contactUserInfo?.fullName?.en === userInfo?.fullName?.en &&
    contactUserInfo?.email === userInfo?.email &&
    contactUserInfo?.mobileNumber === userInfo?.mobileNumber;

  const initialValues = {
    customer_title: userInfo?.title || "",
    customer_engName: userInfo?.fullName?.en || "",
    customer_chiName: userInfo?.fullName?.zh || "",
    customer_idType: userInfo?.idType || "",
    customer_idNum: userInfo?.idNumber || "",
    customer_dateOfBirth:
      userInfo?.birthday || undefined,
    customer_companyName: userInfo?.companyName || "",
    customer_jobPosition: userInfo?.companyPosition || "",
    customer_email: userInfo?.email || user?.email || "",
    customer_mobileNo: userInfo?.mobileNumber || "",
    customer_address1: userInfo?.mailingAddress[0] || "",
    customer_address2: userInfo?.mailingAddress[1] || "",
    customer_district: userInfo?.mailingDistrict || "",
    // contact_isSameAsCustomerInfo: contactUserInfo?.isSameAsCustomerInfo || false,
    contact_isSameAsCustomerInfo: contactInfoIsSameAsCustomerInfo || false,
    // contact_title: contactUserInfo?.title || "",
    contact_engName: contactUserInfo?.fullName?.en || "",
    // contact_chiName: contactUserInfo?.fullName?.zh || "",
    contact_email: contactUserInfo?.email || "",
    contact_mobileNo: contactUserInfo?.mobileNumber || "",
    delivery_method:
      deliveryInfo?.deliveryMethod ||
      (data?.isAllowedPickup ? "pickup" : "delivery"),
    delivery_isSameAsMailingAddress:
      deliveryInfo?.isSameAsMailingAddress || false,
    delivery_addressId: deliveryInfo?.deliveryAddressSelectedId || "",
    delivery_address1: deliveryInfo?.deliveryAddress?.[0] || "",
    delivery_address2: deliveryInfo?.deliveryAddress?.[1] || "",
    delivery_district: deliveryInfo?.deliveryDistrict || "",
    delivery_date: deliveryInfo?.deliveryDate || "",
    delivery_time: deliveryInfo?.deliveryTime || "",
    payment_method: "creditCard",
    is_direct_sale_tnc_accepted: false,

  };

  return initialValues;
};

const processUserInfo = (data) => {
  const isSameAsMailingAddress = data.delivery_isSameAsMailingAddress;
  const isSameAsCustomerInfo = data.contact_isSameAsCustomerInfo;

  // TODO
  let body = {
    userForm: {
      userInfo: {
        title: data.customer_title,
        fullName: {
          en: data.customer_engName,
          zh: data.customer_chiName,
        },
        idType: data.customer_idType,
        idNumber: data.customer_idNum,
        birthday: data.customer_dateOfBirth,
        companyName: data.customer_companyName,
        companyPosition: data.customer_jobPosition,
        email: data.customer_email,
        mailingAddress: [data.customer_address1, data.customer_address2],
        mailingDistrict: data.customer_district,
        mobileNumber: data.customer_mobileNo,
      },
      contactUserInfo: {
        // title: isSameAsCustomerInfo ? data?.customer_title : data.contact_title,
        fullName: {
          en: isSameAsCustomerInfo ? data?.customer_engName : data.contact_engName,
          // zh: isSameAsCustomerInfo ? data?.customer_chiName : data.contact_chiName,
        },
        email: isSameAsCustomerInfo ? data?.customer_email : data.contact_email,
        mobileNumber: isSameAsCustomerInfo ? data?.customer_mobileNo : data.contact_mobileNo,
      },
      paymentMethod: data.payment_method,
      deliveryInfo: {
        deliveryMethod: data.delivery_method, // delivery, pickup
        deliveryAddressSelectedId: isSameAsMailingAddress
          ? ""
          : data.delivery_addressId,
        deliveryAddress: isSameAsMailingAddress
          ? [data.customer_address1, data.customer_address2]
          : [data.delivery_address1, data.delivery_address2],
        deliveryDistrict: isSameAsMailingAddress
          ? data.customer_district
          : data.delivery_district,
        deliveryDate: data.delivery_date,
        deliveryTime: data.delivery_time,
        isSameAsMailingAddress: isSameAsMailingAddress,
      },
    },
  };

  if (data.delivery_method === "pickup")
    delete body.userForm.deliveryInfo.isSameAsMailingAddress;

  return body;
};

const getValidationSchema = (hasPlan) => {
  if (hasPlan) {
    return withPlanValidationSchema;
  } else {
    return deviceOnlyOrPlanCommonValidationSchema;
  }
}

// All fields for device only order and common fields for with plan order
const deviceOnlyOrPlanCommonValidationSchema = Yup.object().shape({
  customer_engName: getOnlyEnglishStringYUP(),
  customer_email: Yup.string()
    .email("error:invalidEmail")
    .required("error:required"),
  customer_mobileNo: getMobileNumYUP(),
  customer_address1: Yup.string().required("error:required"),
  customer_address2: Yup.string().required("error:required"),
  customer_district: Yup.string().required("error:required"),
  contact_isSameAsCustomerInfo: Yup.boolean(),
  contact_engName: Yup.string().when('contact_isSameAsCustomerInfo', {
    is: false,
    then: (schema) => getOnlyEnglishStringYUP()
  }),
  contact_email: Yup.string().when('contact_isSameAsCustomerInfo', {
    is: false,
    then: (schema) => schema
      .email("error:invalidEmail")
      .required("error:required")
  }),
  contact_mobileNo: Yup.string().when('contact_isSameAsCustomerInfo', {
    is: false,
    then: (schema) => getMobileNumYUP()
  }),
  delivery_time: Yup.string().required("checkout:invalidReceiptTime"),
  delivery_date: Yup.string()
                    .required("error:required")
                    .test('isValidDate', 'error:invalidDate', function (value) {
                      const { minDate } = this?.options?.context; // must not use arrow function to get "this" object
                      return minDate.isBefore(dayjs(value, "DD/MM/YYYY"), "day") || false;
                    }),
  delivery_isSameAsMailingAddress: Yup.boolean(),
  delivery_address1: Yup.string().when('delivery_isSameAsMailingAddress', {
    is: false,
    then: (schema) => schema.required("error:required")
  }),
});

const withPlanValidationSchema = Yup.object().shape({
  ...deviceOnlyOrPlanCommonValidationSchema.fields,
  customer_title: Yup.string().required("error:required"),
  customer_chiName: getOnlyChineseStringYUP(),
  customer_idType: Yup.string().required("error:required"),
  customer_idNum: Yup.string()
    .required("error:required")
    .when('customer_idType', (value, schema) => {
      if (value.includes("hkid")) {
        return schema.matches(/^[A-Z]{1,2}[0-9]{6}\([0-9A]\)$/, "error:invalidIdNumber")
      }
      return schema;
    }),
  customer_dateOfBirth: Yup.string().required("error:required"),
  customer_companyName: Yup.string().required("error:required"),
  customer_jobPosition: Yup.string().required("error:required"),
  is_direct_sale_tnc_accepted: Yup.boolean().required("error:required")
    .oneOf([true], "error:required"),
});

const getMinDate = (data, deliveryType) => {
  const keyPath =
    deliveryType === "pickup"
      ? "delivery.estimatePickupDay"
      : "delivery.estimatedDeliveryDay";
  const find = _.find(data, ["keyPath", keyPath]);
  return find ? dayjs(Date.now()).add(find.value, "day") : dayjs(Date.now());
};

const UserInfoForm = forwardRef(({
  children,
  data,
  configData,
  step,
  setStep,
  currentStep,
  skipValidate,
  setSkipValidate,
  hasPlan,
  onNext,
  isLoading,
}, ref) => {
  const { t } = useTranslation("checkout");
  const { globalState } = useContext(GlobalContextStore);

  const { allRequiredPicsTncsAccepted } = useCheckoutGlobalTnc();

  const initialValues = getInitialValues(data, globalState?.userInfo);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validate: (values) => {
      if (skipValidate) return {};

      const validationSchema = getValidationSchema(hasPlan);
      const minDate = getMinDate(configData, values.delivery_method);
      try {
        validateYupSchema(
          values,
          validationSchema,
          true,
          { minDate }
        );
      } catch (err) {
        return yupToFormErrors(err);
      }
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (payload, { setErrors }) => {
      const body = processUserInfo(payload);
      console.log("submit userInfo", body);

      onNext && onNext(body);
    },
  });

  const { formValues: localStorageFormValues } = useLocalStorageFormData({
    formik,
    initialValues,
    formKey: "checkoutFormV1",
  })

  useEffect(() => {
    // bind external tnc status to lcoal formik
    formik.setFieldValue("is_direct_sale_tnc_accepted", allRequiredPicsTncsAccepted)
  }, [allRequiredPicsTncsAccepted])

  // if errors, scroll to error field
  useEffect(() => {
    if (!_.isEmpty(formik.errors)) {
      const keys = Object.keys(formik.errors);
      const firstError = keys[0];
      const element = document.getElementsByName(firstError)[0];
      element?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [formik.errors]);

  useEffect(() => {
    // TODO: only trigger setValues when different from initialValues
    if (!_.isEmpty(localStorageFormValues)) {
      formik.setValues(localStorageFormValues);
    }
  }, [localStorageFormValues])

  const handleSaveForm = async () => {
    await setSkipValidate(true);
    formik.submitForm();
  }

  const handleSubmit = () => {
    formik.setErrors({});
    formik.submitForm();
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <StepContainer
          step={step}
          setStep={setStep}
          currentStep={currentStep}
          title={t("customerInformation")}
          onSave={handleSaveForm}
          onProceedNextStep={handleSubmit}
          isLoading={isLoading}
          ref={ref}
        >
          {children}
        </StepContainer>
      </Form>
    </FormikProvider>
  );
});

export default UserInfoForm;
