import { Divider, Grid } from "@mui/material";
import { useField } from "formik";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Space from "../../../components/Space";
import MyTypography from "../../../components/Typography";
import theme from "../../../theme";
import { breakpoint } from "../../../utils/responsive";
import useCheckoutGlobalTnc from "../hooks/useCheckoutGlobalTnc";
import DirectSaleTncSection from "../tnc/DirectSaleTncSection";
import TNCItem from "../tnc/TncItem";
import Form from "./Form";

const PersonalInformationStatement = ({
  data,
  acceptedTNC,
  setAcceptedTNC,
}) => {
  const { t } = useTranslation("checkout");

  //const [directSale, setDirectSale] = useState([]);
  const { directedSalesTncs } = useCheckoutGlobalTnc();

  return (
    <Form title={t("personalInformationStatement")}>
      <div>
        <Divider />
      </div>

      {data &&
        <>
          {_.filter(data, ["keyPath", "tnc.savePersonalInformation"])?.map(
            (item) => {
              return (
                item.value && (
                  <Grid item xs={12} style={{ padding: 0 }}>
                    <TNCItem
                      id={item?.value?._id}
                      keyPath={item?.keyPath?.replace("tnc.", "")}
                      title={item?.value?.title}
                      shortDescription={item?.value?.shortDescription}
                      detail={item?.value?.description}
                      acceptedTNC={acceptedTNC}
                      setAcceptedTNC={setAcceptedTNC}
                      disableDivider
                    />
                    <Divider />
                  </Grid>
                )
              );
            }
          )}
        </>
      }

      {directedSalesTncs.length > 0 && (
        <Grid item xs={12} container direction="column" style={{ paddingTop: "1px" }}>
          <DirectSaleTncSection
            acceptedTNC={acceptedTNC}
            setAcceptedTNC={setAcceptedTNC}
            disableDivider={true}
          />
          <Divider />
        </Grid>
      )}

      <Grid item xs={12} paddingX={{ xs: "25px", md: "30px" }}>
        <MyTypography>{t("personalInfomationRemark")}</MyTypography>
      </Grid>
    </Form>
  );
};

export default PersonalInformationStatement;
