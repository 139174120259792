import styled from "styled-components";
import CommonCheckbox from "../Common/CommonCheckbox"
import EditIcon from "../../assets/ic-edit.svg";
import DeleteIcon from "../../assets/ic-delete.svg";
import Space from "../Space";
import MyTypography from "../Typography";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import EditAddressDialog from "../../pageComponents/checkout/userInfo/EditAddressDialog";
import { useState, useContext } from "react";
import { deleteAddress } from "../../apis";
import { appearGlobalError, updateUserInfo } from "../../requests/globalRequest";
import { GlobalContextStore } from "../../context/GlobalProvider";

const EditAddressCheckbox = ({
  disableEdit,
  addressId,
  addressOne,
  addressTwo,
  district,
  checked,
  handleAutoSubmit,
  handleDelete,
}) => {
  const { t } = useTranslation("checkout");
  const { values, setValues } = useFormikContext();
  const { globalDispatch } = useContext(GlobalContextStore);
  const [isOpen, setIsOpen] = useState(false);

  const onClick = () => {
    setValues({
      ...values,
      delivery_addressId: addressId,
      delivery_address1: addressOne,
      delivery_address2: addressTwo,
      delivery_district: district,
    });
  };

  const onDelete = async (e) => {
    e.stopPropagation();
    handleAutoSubmit();

    try {
      const result = await deleteAddress(addressId);

      if (result) {
        setValues({
          ...values,
          delivery_addressId: "",
          delivery_address1: "",
          delivery_address2: "",
          delivery_district: "",
        });
        handleDelete();
        updateUserInfo(globalDispatch);
      }
    } catch (e) {
      console.log("delete address error - ", e);
      appearGlobalError(globalDispatch, e?.result?.code, e?.result?.errorMessage || e?.msg, {type: "fail"});
    }
  };

  const _renderAddress = () => {
    return (
      <div style={{ padding: "10px" }}>
        <MyTypography fontSize="16"> {t("address")}</MyTypography>
        <Space size="15px" />
        <MyTypography fontSize="18" breakWord> {addressOne}</MyTypography>
        <MyTypography fontSize="18" breakWord> {addressTwo}</MyTypography>
        <Space size="50px" />
        <MyTypography fontSize="16"> {t("district")}</MyTypography>
        <Space size="15px" />
        <MyTypography fontSize="18"> {t(district)}</MyTypography>
      </div>
    );
  };

  return (
    <Container onClick={onClick}>
      <Title>
        <CommonCheckbox
          key={`checkbox-${addressId}-option`}
          checked={values.delivery_addressId === addressId}
        />
        {/* TODO - edit, delete address */}
        {!disableEdit && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              src={EditIcon}
              onClick={() => {
                setIsOpen(true);
              }}
            />
            <Space width="25px" />
            <IconButton src={DeleteIcon} onClick={(e) => onDelete(e)} />
          </div>
        )}
      </Title>

      {_renderAddress()}

      {isOpen && (
        <EditAddressDialog
          isOpen={isOpen}
          handleAutoSubmit={handleAutoSubmit}
          setIsOpen={setIsOpen}
          addressId={addressId}
          addressOne={addressOne}
          addressTwo={addressTwo}
          district={district}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  border-radius: 25px;
  box-shadow: 0 0 6px 0 #c9c9c9;
  background-color: #fff;
  padding: 15px;
  cursor: pointer;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
`;

const IconButton = styled.img`
  cursor: pointer;
  max-width: 20px;
  max-height: 20px;
`;

export default EditAddressCheckbox;
