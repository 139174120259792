import CommonSelect from "./Common/CommonSelect";
import { useField } from "formik";

const MyPicker = ({
  title,
  titleColor = "#333333",
  titleSize,
  getOptionLabelField = "title",
  required = false,
  options,
  id,
  viewOnly,
  placeholder,
  hintText,
  multiLevel = false,
  ...props
}) => {
  const [field, meta, helpers] = useField(id);

  return (
    <CommonSelect
      title={title}
      error={meta.error}
      helperText={meta.error}
      required={required}
      hintText={hintText}
      options={options}
      viewOnly={viewOnly}
      value={field.value}
      onChange={(e) => helpers.setValue(e.target.value || "")}
      multiLevel={multiLevel}
    />
  );
};

export default MyPicker;
