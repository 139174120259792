import Container from "../../../components/Container";
import Collapse from "../../../components/Collapse";
import Typography from "../../../components/Typography";
import { Grid } from "@mui/material";
import theme from "../../../theme";
import styled from "styled-components";
import DeviceContent from "../../../components/OrderItems/DeviceContent";
import PlanContent from "../../../components/OrderItems/PlanContent";
import CollapseTitleProp from "./CollaspeTitleProp";
import CollapseSubTitleProp from "./CollaspeSubTitleProp";
import ItemTableRow from "../../../components/OrderItems/ItemTableRow";
import { Divider } from "@mui/material";
import Space from "../../../components/Space";
import DeadlineHint from "../pendingDocument/DeadlineHint";
import { fetchUserOrderById } from "../../../apis";

import DeviceOrder from "./DeviceOrder";
import PlanOrder from "./PlanOrder";
import SpecialOrder from "./SpecialOrder";

import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { WindowSize } from "../../../utils/responsive";
import { useMemo } from "react";

const OrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 30px 30px 30px;
  margin-top: -35px;
`;

const getTitlePropValue = (data) => {
  return {
    index: data?.index || "",
    orderNo: data?.orderNumber || "",
    status: data?.orderStatus || "",
    date: data?.createdAt || "",
    quantity: data?.quantity || 0,
  };
};

const getDeliveryItemDetails = (data) => {
  return {
    ...data,
    itemId: data?.orderItemId || "",
    subtotal: data?.discountedPrice || 0,
    itemName: data?.delivery?.deliveryInfo?._displayName,
  };
};

const OrderItem = (props) => {
  const { data, index, defaultExpand = false, onClickPayAgain, onClickDownloadAF } = props;
  const [isExpand, setIsExpand] = useState(defaultExpand);

  const isMobile = WindowSize() === "mobile";

  const {
    data: orderData,
    refetch,
    isLoading,
  } = useQuery(
    ["orderById", { id: data?.orderId }],
    () => fetchUserOrderById(data?.orderId),
    {
      enabled: isExpand,
    }
  );

  // console.log('==orderNumber', data?.orderNumber)
  // console.log(`==order details item${index}`, orderData?.data)

  const handleOnClickPayAgain = () => {
    onClickPayAgain && onClickPayAgain(data?.orderId);
  };

  const handleOnClickDownloadAF = () => {
    onClickDownloadAF && onClickDownloadAF(data?.orderId);
  }

  const ItemCardBaseStyle = {
    boxShadow: theme.innerContainerShadow,
    marginBottom: 0,
  };

  const isAfReady = useMemo(() => data?.isAfReady || false, [data])

  return (
    <Container style={{ boxShadow: theme.outerContainerShadow, padding: 0 }}>
      <div>
        <Collapse
          titleProp={<CollapseTitleProp
            data={getTitlePropValue({ ...data, index: index })}
            onClickPayAgain={handleOnClickPayAgain}
            onClickDownloadAF={handleOnClickDownloadAF}
            isAfReady={isAfReady}
          />}
          descriptionProp={
            isMobile ? (
              <CollapseSubTitleProp
                  data={getTitlePropValue({ ...data, index: index })}
                  onClickPayAgain={handleOnClickPayAgain}
                  onClickDownloadAF={handleOnClickDownloadAF}
                  isAfReady={isAfReady}
                />
            ) : null
          }
          isOpen={isExpand}
          onExpand={() => setIsExpand((prev) => !prev)}
        >
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <OrderContainer>
              {orderData?.data?.map((item, index) => {
                if (item?.type === "product")
                  return (
                    <DeviceOrder
                      data={item}
                      index={index}
                      cardBaseStyle={ItemCardBaseStyle}
                    />
                  );
                else if (item?.type === "plan")
                  return (
                    <PlanOrder
                      data={item}
                      index={index}
                      cardBaseStyle={ItemCardBaseStyle}
                    />
                  );
                else if (item?.type === "delivery")
                  return (
                    <SpecialOrder
                      type={"delivery"}
                      data={getDeliveryItemDetails(item)}
                      cardBaseStyle={ItemCardBaseStyle}
                    />
                  );
                else return null;
              })}

              { orderData?.data?.[0]?.promoCode && (
                <SpecialOrder 
                  type={"discount"} 
                  data={{
                    itemName: {en: orderData?.data?.[0]?.promoCode, zh: orderData?.data?.[0]?.promoCode},
                    description: orderData?.data?.[0]?.promoCodeName || null,
                    subtotal: -orderData?.data?.[0]?.promoCodeDiscount,
                    quantity: 1,
                  }}
                  cardBaseStyle={ItemCardBaseStyle}
                />
              )}
            </OrderContainer>
          )}
        </Collapse>
      </div>
    </Container>
  );
};

export default OrderItem;
