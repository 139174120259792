import { useState, useEffect, useMemo, forwardRef, useImperativeHandle } from "react";
import styled from "styled-components";
import { Form, FormikProvider, useFormik, useField } from "formik";
import { Grid } from "@mui/material";
import MuiButton from "@mui/material/Button";
import { formatCurrency } from "../../utils/common";
import Select from "../../components/MenuItemSelect";
import Button from "../../components/Button";
import Collapse from "../../components/Collapse";
import CheckboxGroup from "../../components/CheckboxGroup";
import Divider from "../../components/Divider";
import DevicePriceBar from "../../components/BottomBar/DevicePriceBar";
import Typography from "../../components/Typography";
import theme from "../../theme";
import QuantityField from "./QuantityField";
import ExtraService from "./ExtraService";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import REEServiceField from "./REEServiceField";
import { WindowSize } from "../../utils/responsive";
import IPhoneTNC from "./iphoneTNC";
import ProductTitle from "./ProductTitle";
import DevicePrice from "./Price";
import ShippingCollapse from "./ShippingCollapse";
import SKUSelectField from "./SKUSelectField";
import _ from "lodash";

const ProductInfoButton = styled(MuiButton)`
    height: 48px;

    && {
        color: #3e3e3e;
        border-radius: 12px;
        font-size: ${theme.fontSize.button}px;
        font-weight: 500;
        text-transform: none;
        background: #ebebeb;
    }
`

const getOptions = (device, cartPayload=null) => {
    if (cartPayload?.options?.variationProduct?.options) {
        return cartPayload?.options?.variationProduct?.options?.map((option) => {
            const { optionKey, valueKey } = option;

            const targetOptionObject = device.productOptions.find((item) => item?.option?.optionKey === optionKey);
            const targetValueObject = targetOptionObject?.values.find((item) => item.value === valueKey)

            return { _id: targetOptionObject?.option?._id, value: [targetValueObject?._id] }
        })
    }

    return (device?.productOptions || []).map((option) => ({ _id: option.option._id, value: [] }))
}

const getInitialFormData = (device, cartPayload=undefined) => {
    // TODO: check cartPayload after refactor
    const initialValues = {
        qty: cartPayload?.quantity || device?.minQuantity,  // default device min quantity is 1
        options: getOptions(device, cartPayload),
        freeVas: cartPayload?.options?.freeVas?.length > 0 ? cartPayload.options.freeVas.map((item) => device.freeVas.find((vas) => vas._id === item)) : [],
        paidVas: cartPayload?.options?.paidVas?.length > 0 ? cartPayload.options.paidVas.map((item) => device.paidVas.find((vas) => vas._id === item)) : [],
        needRemovalService: _.isBoolean(cartPayload?.options?.needRemovalService) ? cartPayload?.options?.needRemovalService : undefined,
        tnc: [],
    };

    // console.log("==initialValues", initialValues)
    return initialValues;
}

const getValidationSchema = (device) => {
    const { haveFreeRemoval=false, detailTncs=[] } = device;

    const schema = {
        qty: Yup.number().required("device:required"),
        options: Yup.array().of(
            Yup.object().shape({
                _id: Yup.string().required("device:required"),
                value: Yup.array().of(Yup.string()).required("device:required").min(1, "device:required"),
            })
        ),
        freeVas: Yup.array().of(
            Yup.object().shape({
                _id: Yup.string().required("device:required"),
            })
        ),
        paidVas: Yup.array().of(
            Yup.object().shape({
                _id: Yup.string().required("device:required"),
            })
        ),
        needRemovalService: haveFreeRemoval ? Yup.boolean().required("device:required") : undefined,
        tnc: detailTncs.length > 0 ? Yup.array().of(Yup.string().required("device:required")).required("device:required").min(detailTncs.length, "device:required") : undefined,
    }

    return Yup.object().shape(schema);
}

const DeviceForm = forwardRef((props, ref) => {
    const { 
        device, 
        selectedVariant,
        cartPayload,
        onClickInfoButton, 
        handleLastOptionItemOnChange,
        onSubmit,
        isEdit,
        isSubmitDisabled=false,
    } = props;

    const { t, i18n } = useTranslation(['device', 'common']);
    const isMobile = WindowSize() === "mobile";

    const initialErrorsForOnLoadDisableAddToCart = (!cartPayload || (cartPayload && device?.detailTncs?.length > 0)) && device?.productOptions?.length > 0
                                                    ? { initialForOnLoadDisableAddToCart: true } 
                                                        : undefined;

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: getInitialFormData(device, cartPayload),
        initialErrors: initialErrorsForOnLoadDisableAddToCart,
        validationSchema: getValidationSchema(device),
        // validateOnChange: false,
        // validateOnBlur: false,
        onSubmit: (payload) => {
            // console.log("==add device", payload);
            // console.log("==selected variant", selectedVariant)

            const request = {
                product: device._id,
                quantity: payload.qty,
                options: {
                    needRemovalService: device?.haveFreeRemoval ? payload.needRemovalService : undefined,
                    variationProduct: selectedVariant?._id || undefined,
                    freeVas: payload.freeVas.map((item) => item?._id || item?.id),
                    paidVas: payload.paidVas.map((item) => item?._id || item?.id),
                    detailTncs: payload.tnc.map(item => ({ tncId: item, accepted: true }))
                }
            }

            const gaRequest = {
                ...device,
                quantity: payload.qty,
                variant: selectedVariant,
                freeVas: payload?.freeVas || [],
                paidVas: payload?.paidVas || [],
            }
            
            onSubmit && onSubmit(request, gaRequest);
        },
    });

    const isFirstSubmit = formik?.submitCount < 1;
    const isValid = formik?.isValid;    // TODO

    // console.log("==formik.value", formik?.values)
    // console.log("==formik.errors", formik?.errors)

    const handleSubmit = () => {
        formik.handleSubmit();
    }

    const totalPrice = useMemo(() => {
        const { freeVas=[], paidVas=[], qty=1 } = formik?.values;

        let totalPrice = selectedVariant ? selectedVariant?.sellingPrice || selectedVariant?.markedPrice : device?.sellingPrice || device?.markedPrice || 0;
        if (paidVas.length > 0) paidVas.forEach(item => totalPrice += item?.discountedPrice || 0)
        // if (freeVas.length > 0) freeVas.forEach(item => totalPrice += item?.discountedPrice || 0)

        return totalPrice * qty;
    }, [formik?.values, selectedVariant, device])

    useEffect(() => {
        // Check all options are selected
        const isAllOptionSelected = device?.productOptions?.every((item, index) => 
            formik?.values?.options?.[index]?.value?.length > 0
        )

        if (isAllOptionSelected && handleLastOptionItemOnChange) {
            handleLastOptionItemOnChange(formik?.values?.options);
        }
    }, [formik?.values])

    useEffect(() => {
        if (!selectedVariant?.purchaseLimitPerUser) return;
        if (formik?.values?.qty > selectedVariant.purchaseLimitPerUser) {
            formik.setFieldValue('qty', selectedVariant.purchaseLimitPerUser);
        }
    }, [selectedVariant?.purchaseLimitPerUser])

    const maxQuantity = useMemo(() => {
        // compare device?.maxQuantity and selectedVariant?.purchaseLimitPerUser if both are available

        if (selectedVariant?.purchaseLimitPerUser && device?.maxQuantity) {
            return Math.min(selectedVariant.purchaseLimitPerUser, device.maxQuantity);
        } else {
            return selectedVariant?.purchaseLimitPerUser || device?.maxQuantity || undefined;
        }
    }, [device, selectedVariant])

    useImperativeHandle(ref, () => ({
        setError: (error) => formik.setErrors(error),
    }), [formik]);

    return (
        <Grid item md>
            <FormikProvider value={formik}>
                <Form>
                    <Grid container gap={'25px'} flexDirection={'column'}>
                        {/* Product Name & SKU */}
                        <ProductTitle device={device} t={t} locale={i18n.language} />

                        {/* Product price */}
                        <DevicePrice device={selectedVariant ? selectedVariant : device} />

                        {/* Select SKU */}
                        { device?.productOptions && device?.productOptions.length > 0 && (
                            <Grid container item spacing={'21.9px'}>
                                { device.productOptions.map((item, index) => {
                                    const { option, values } = item;
                                    const isLastItem = index === device.productOptions.length - 1;
                                    const isDisabled = index > 0 ? formik?.values?.options?.[index - 1]?.value && formik?.values?.options?.[index - 1]?.value.length === 0 : false;

                                    return (
                                        <SKUSelectField
                                            key={`selectField-${option.optionKey}`}
                                            id={`options[${index}].value`}
                                            title={option.label}
                                            options={values}
                                            locale={i18n.language}
                                            readOnly={isDisabled}
                                            onChange={(optionSelected) => {
                                                formik.setFieldValue(`options[${index}].value`, optionSelected);

                                                if (!isLastItem) {
                                                    // Clear all remaining options value.
                                                    for (let i = index + 1; i < device.productOptions.length; i++) {
                                                        // Check if this option has only 1 value which need to be selected by default.
                                                        const newValue = device.productOptions[i].values.length === 1 ? [device.productOptions[i].values[0]._id] : [];
                                                        formik.setFieldValue(`options[${i}].value`, newValue);
                                                    }
                                                }
                                            }}
                                            t={t}
                                            showError={!isFirstSubmit}
                                        />
                                    )
                                })}
                            </Grid>
                        )}

                        {/* Free Gift */}
                        { device?.freeVas && device.freeVas.length > 0 && (
                            <ExtraService 
                                key={'freeVas'}
                                id={'freeVas'}
                                title={t('freeVas')}
                                data={device.freeVas}
                                locale={i18n.language}
                            />
                        )}

                        {/* Paid Vas */}
                        { device?.paidVas && device.paidVas.length > 0 && (
                            <ExtraService 
                                key={'paidVas'}
                                id={'paidVas'}
                                title={t('paidVas')}
                                data={device.paidVas}
                                locale={i18n.language}
                            />
                        )}

                        {/* T&C */}
                        { device.haveFreeRemoval && (
                            <REEServiceField t={t} isMobile={isMobile} showError={!isFirstSubmit} />
                        )}

                        { device?.detailTncs && device.detailTncs.length > 0 && (
                            <IPhoneTNC t={t} tncs={device.detailTncs} locale={i18n.language} showError={!isFirstSubmit} />   
                        )}

                        <Grid item><Divider orientation="horizontal" flexItem /></Grid>
                        
                        <Grid item container gap={'22px'}>
                            <Grid item xs>
                                <QuantityField
                                    id="qty"
                                    t={t}
                                    minQuantity={device.minQuantity}
                                    maxQuantity={maxQuantity}
                                />
                            </Grid>
                            <Button
                                size={"medium"}
                                color="yellow"
                                style={{ width: isMobile ? '100%' : 200 }}
                                onClick={handleSubmit}
                                disabled={!isValid || isSubmitDisabled}
                            >
                                {isEdit ? t('common:save') : t('common:addToCart')}
                            </Button>
                        </Grid>

                        <Grid item><Divider orientation="horizontal" flexItem /></Grid>

                        {/** Remark */}
                        {/* Refers to "Purchase limit per user" setting of each variant (uat-comments#89) */}
                        <Grid item container gap={2} flexDirection="column">
                            { (selectedVariant?.purchaseLimitPerUser > 0) && (
                                <Grid item>
                                    <Typography style={{ fontSize: '14px', color: theme.dirtyYellow }}>
                                        {t('maxPurchaseQuantity', { maxQuantity: selectedVariant.purchaseLimitPerUser })}
                                    </Typography>
                                </Grid>
                            )}

                            <ShippingCollapse allowDelivery={device.isAllowedDelivery} allowPickup={device.isAllowedPickUp} t={t} />

                            <Grid item>
                                <ProductInfoButton fullWidth onClick={onClickInfoButton}>{t('productDetails')}</ProductInfoButton>
                            </Grid>

                            <Grid item style={{ marginTop: 36 }}>
                                <Typography style={{ fontSize: 14, color: theme.lightBlack }}><span style={{ color: theme.mandatory, marginRight: '3px' }}>*</span>{t('required')}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <DevicePriceBar disabled={!isValid || isSubmitDisabled} totalPrice={totalPrice} onClick={handleSubmit} titleShowTotal isEdit={isEdit} />
                </Form>
            </FormikProvider>
        </Grid>
    )
});

export default DeviceForm;