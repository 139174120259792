import { Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useField, useFormikContext } from "formik";
import _, { isEmpty } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { INITIAL_PORTIN } from ".";
import { fetchDocUploadConfig, fetchMNPActivationHolidayConfig, fetchNewNumberActivationConfig, requestPortinOtp, verifyPortinOtp } from "../../../../apis";
import MyButton from "../../../../components/Button";
import CountdownButton from "../../../../components/Buttons/CountdownButton";
import YellowBubbleCheckbox from "../../../../components/Checkboxs/YellowBubbleCheckbox";
import CommonCheckbox from "../../../../components/Common/CommonCheckbox";
import SimpleDialog from "../../../../components/CommonDialog/SimpleDialog";
import DatePicker from "../../../../components/DateTimePicker/DatePicker";
import Select from "../../../../components/Select";
import Space from "../../../../components/Space";
import SwitchButton from "../../../../components/SwitchButton";
import MyTextButton from "../../../../components/TextButton";
import MyTextField from "../../../../components/TextField";
import WarnText from "../../../../components/Typographies/WarnText";
import MyTypography from "../../../../components/Typography";
import { GlobalContextStore } from "../../../../context/GlobalProvider";
import { appearGlobalError } from "../../../../requests/globalRequest";
import theme from "../../../../theme";
import { GetWord, isEmptyValues } from "../../../../utils/common";
import { WindowSize } from "../../../../utils/responsive";
import { DateRangeHelper } from "../../../../utils/dateRange";
import { CheckoutFormContext } from "../../context/CheckoutFormContext";
import { MNP_EFFECTIVE_TIME_SLOT } from "./constant";
import { gaSelectPortInNumber } from "../../../../utils/ga";
import { gtmSelectPortInNumber } from "../../../../utils/gtm";



const DEFAULT_TNC = {
  isOpen: false,
  title: "",
  content: "",
};

const SERVICE_PROVIDER_LIST = [
  { value: "ch" },
  { value: "cm" },
  { value: "ct" },
  { value: "cu" },
  { value: "fa" },
  { value: "h3" },
  { value: "hc" },
  { value: "hd" },
  { value: "hg" },
  { value: "hp" },
  { value: "m3" },
  { value: "mp" },
  { value: "np" },
  { value: "pp" },
  { value: "s3" },
  { value: "sg" },
  { value: "sp" },
  { value: "t3" },
  { value: "tc" },
  { value: "tg" },
  { value: "tp" },
  { value: "tv" },
  { value: "sm" },
  { value: "td" },
  { value: "hb" },
  { value: "truphone" },
  { value: "birdie" },
  { value: "others" },
]

const validate = (value, helpers) => {
  const validateAgreeChangeOwner = () => {
    let error = {}
    if (value['unableToProvideOTP']) {
      if (value['agreeChangeOwner'] !== true) {
        error = { ...error, agreeChangeOwner: "error:required" }
      }
    }

    return error
  }

  const validateOtherFields = () => {
    let error = {}
    const SKIP_CHECKING_FIELDS = ["unableToProvideOTP", "realNameRegistered", "agreeChangeOwner", "phoneOtpId"];

    // validate fields
    for (let key in value) {
      if (
        value.hasOwnProperty(key) &&
        (isEmptyValues(value[key]) || value[key] === false) &&
        !SKIP_CHECKING_FIELDS.includes(key)
      ) {
        if (key === "otp" && value["unableToProvideOTP"]) {
          continue;
        } else {
          error = { ...error, [key]: "error:required" };
        }
      }
    }

    return error
  }
  let error = {};


  error = { ...error, ...validateAgreeChangeOwner() }
  error = { ...error, ...validateOtherFields() }
  console.log("🚀 ~ validate ~ error:", error)

  // if (value.simType === "postpaid") {
  //   delete error.acceptedPrepaidTnc;
  // } else {
  //   delete error.acceptedPostpaidTnc;
  //   if (value.realNameRegistered !== true) {
  //     delete error.acceptedPrepaidTnc;
  //   }
  // }

  //TODO - testing code for no prepaid/postpaid dummy data
  delete error.acceptedPrepaidTnc;
  delete error.acceptedPostpaidTnc;

  helpers.setError(error);
  return _.isEmpty(error);
};

const validateMobileNumber = (value) => {
  if (!value) {
    return "error:required";
  }
  if (!/^[0-9]{8}$/.test(value)) {
    return "error:invalidMobile";
  }
  return undefined;
};


const ExistingNumberDialog = ({ isOpen, setIsOpen, name, tncData, savedData, setSavedData }) => {
  const { values } = useFormikContext();
  const quoteItemIndex = parseInt(name.split(".")[0].match(/\d+/g)[0], 0);
  const quoteItemId = values?.order_list[quoteItemIndex]?._id;
  const { t } = useTranslation(["checkout", "common", ]);
  const { globalDispatch } = useContext(GlobalContextStore);
  const isMobile = WindowSize() === "mobile";
  const [isOpenTNC, setIsOpenTNC] = useState(DEFAULT_TNC);
  const [field, meta, helpers] = useField(name);
  const [otpStatus, setOtpStatus] = useState("normal");

  const { calendarQuery: publicHolidayCalendarQuery } = useContext(CheckoutFormContext)

  const isEdit = useMemo(() => !isEmpty(savedData), [savedData])

  const { data: docUploadConfig } = useQuery({ queryKey: ['docUploadPdf'], queryFn: fetchDocUploadConfig })
  const { data: mnpActiviationHolidaysConfig } = useQuery({ queryKey: ['mnpActiviationHolidays'], queryFn: fetchMNPActivationHolidayConfig })

  const mnpDownloadFormUrl = docUploadConfig?.data?.find(item => item?.keyPath === "doc_upload.mnpDownloadForm")?.valueUrl;
  const changeOwnershipFormUrl = docUploadConfig?.data?.find(item => item?.keyPath === "doc_upload.changeOwnershipForm")?.valueUrl;
  const mnpHolidayData = mnpActiviationHolidaysConfig?.data?.find(item => item?.keyPath === "plan.mnpActivationHolidays")?.value || [];
  const activationQuery = useQuery({
    queryKey: ["fetchNewNumberActivationConfig"],
    queryFn: fetchNewNumberActivationConfig,
    refetchOnWindowFocus: false
  })

  const isUnableToProvideOTP = field?.value?.unableToProvideOTP;
  const isSaveButtonEnable = field?.value.acceptedPlanMnpTnc && (
    (!isUnableToProvideOTP && !isEmpty(field?.value?.phoneOtpId))  // case otp required --> filled otp / edit form with otp verified
    || (isUnableToProvideOTP && field?.value.agreeChangeOwner)  // case unable to provide otp --> agree change owner
  );

  const dateRangeHelper = useMemo(() => {

    const mnpNumberActivationMinDay = activationQuery.data?.data?.find(e => e.keyPath === "plan.mnpNumberActivationMinDay")?.value || 5
    const mnpNumberActivationMaxDay = activationQuery.data?.data?.find(e => e.keyPath === "plan.mnpNumberActivationMaxDay")?.value || 14
    const helper = new DateRangeHelper(
      dayjs().startOf('day'),
      mnpNumberActivationMinDay,
      mnpNumberActivationMaxDay,
      {
        day: [0, 6], // weekend
        otherDates: mnpHolidayData.concat(publicHolidayCalendarQuery.data?.data ?? [])
      }
    )
    return helper
  }, [activationQuery, publicHolidayCalendarQuery, mnpHolidayData])

  const isMnpFormRequired = () => {
    const simType = field.value?.simType;
    const realNameRegistered = field.value?.realNameRegistered;

    if (simType === "postpaid") {
      return isUnableToProvideOTP;
    } else {
      return !(realNameRegistered && !isUnableToProvideOTP);
    }
  }

  const onChange = (fieldId, val) => {
    helpers.setValue({ ...field.value, [fieldId]: val });
  };

  const handleUnsetMnpNumberFields = () => {
    if (!isEdit) {
      // On create close dialog, reset the form
      helpers.setValue({ ...INITIAL_PORTIN });
    } else {
      // On edit close dialog, reset the form with saved data if exist
      helpers.setValue({ ...INITIAL_PORTIN, ...savedData });
    }

    // reset error state
    if (meta.error) {
      helpers.setError({ number: undefined, otp: undefined });
    }
    setOtpStatus("normal");
  };

  const handleRequestOtp = async () => {
    setOtpStatus("normal");
    if (meta.error) {
      helpers.setError({ number: undefined, otp: undefined });
    }

    const mobileNumberError = validateMobileNumber(field.value?.number);
    if (mobileNumberError) {
      helpers.setError({ number: mobileNumberError });
      return Promise.reject()
    }

    const result = await requestPortinOtp({ quoteItemId: quoteItemId, number: field?.value?.number });
    if (result && result?.msg === "success") {
      setOtpStatus("requested");
      helpers.setValue({ ...field.value, otp: "", phoneOtpId: undefined });
    } else {
      handleRequestOtpError(result)
    }
  };

  const handleRequestOtpError = (error) => {
    appearGlobalError(globalDispatch, error?.result?.code, error?.result?.errorMessage, {
      type: "fail",
    });
  };

  const handleVerifyOtp = async (otp) => {
    console.log('handleVerifyOtp', otp)
    helpers.setError({ otp: undefined });

    try {
      if (!otp || otp.length !== 6) {
        throw new Error();
      }

      const result = await verifyPortinOtp({
        quoteItemId: quoteItemId,
        number: field?.value?.number,
        otp: otp,
      })
        .then((data) => data && data?.msg === 'success' ? data?.data : Promise.reject(data))
        .catch((err) => {throw err?.result;});

        if (!!result?.verified) {
          const newValue = { ...field.value, otp, phoneOtpId: result?.phoneOtpId };
          helpers.setValue({ ...newValue });
        }
    } catch (err) {
      console.log("🚀 ~ handleVerifyOtp ~ err", err)
      return helpers.setError({ 
        otp: err?.errorMessage ? GetWord(err?.errorMessage) : "error:invalidVerificationCode"
      });
    }
  }

  const handleUpdateMnpNumberField = async () => {
    console.log("submit add new number", field.value);

    if (validate(field.value, helpers)) {
      const newValue = {
        ...field.value,
        phoneOtpId: field.value.unableToProvideOTP ? undefined : field.value?.phoneOtpId,
      };

      helpers.setValue(newValue);
      setSavedData(newValue);

      // GA event
      const gaPayload = {
        plan_type: field.value?.simType,
        RNR: field.value?.simType === "prepaid" 
              ? (field.value?.realNameRegistered ? "Yes" : "No") 
              : "NA",
      }
      gaSelectPortInNumber(gaPayload);
      gtmSelectPortInNumber(gaPayload);

      setIsOpen(false);
      setOtpStatus("normal");
    }
  };

  const handleOpenTncDialog = (tnc) => {
    setIsOpenTNC({
      isOpen: true,
      title: tnc.value?.title,
      content: tnc.value?.description,
    });
  }

  // auto scroll to error field
  useEffect(() => {
    if (meta.error) {
      const element = document.getElementsByClassName("Mui-error")?.[0];
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [meta.error])

  const TNCLabel = () => {
    const tncItem = useMemo(() => {
      if (field.value?.simType === "postpaid") {
        return _.find(tncData, ["keyPath", "tnc.postPaid"]);
      } else {
        return _.find(tncData, ["keyPath", "tnc.prePaid"]);
      }
    }, [tncData, field.value?.simType])

    return (
      <MyTypography>
        {`${t("acceptedPlanMnpTnc")} `}
        <MyTextButton
          onClick={() => handleOpenTncDialog(tncItem)}
          style={{ margin: 0, padding: 0 }}
        >
          {t('termsAndConditions')}
        </MyTextButton>
      </MyTypography>
    )
  }

  return (
    <SimpleDialog
      title={t("mobileNumberPorted")}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      handleClose={handleUnsetMnpNumberFields}
    >
      <Grid container gap={3} flexDirection={"column"}>
        <Grid item container>
          <Grid item xs={12} container spacing={3}>
            <Grid item xs={12} md={6}>
              <MyTextField
                id={`${name}.number`}
                placeholder={t("enterMobileNumber")}
                onChange={(event) => {
                  const value = event.target.value;
                  // clear otp and phoneOtpId when number is changed
                  helpers.setValue({ ...field.value, number: value, otp: "", phoneOtpId: undefined });
                  setOtpStatus("normal");
                }}
              />
            </Grid>
            {!isUnableToProvideOTP && (
                <Grid item xs={12} md={6}>
                  <CountdownButton
                    fullWidth
                    onClick={handleRequestOtp}
                    onError={handleRequestOtpError}
                    interval={60}
                    disabled={field.value?.phoneOtpId}
                  >
                    {t("verificationCode")}
                  </CountdownButton>
                </Grid>
            )}
          </Grid>
          {(otpStatus === "requested" && !isUnableToProvideOTP) && (
            <Grid item xs={12} mt={1}>
              <MyTypography color="orange">
                {t("successRequestOtp")}
              </MyTypography>
            </Grid>
          )}
        </Grid>

        {!isUnableToProvideOTP && otpStatus === "requested" && (
          <Grid item>
            <MyTextField
              id={`${name}.otp`}
              placeholder={t("enterVerificationCode")}
              title={t("verificationCode")}
              required
              error={meta.error?.otp}
              helperText={meta.error?.otp && t(meta.error.otp)}
              onChange={(e) => {
                const value = e.target.value;
                onChange("otp", value);

                if (value.length === 6) {
                  handleVerifyOtp(value);
                }
              }}
            />
          </Grid>
        )}

        <Grid item container flexDirection={"column"} rowSpacing={1}>
          <Grid item>
            <CommonCheckbox
              label={t("failToProvideCode")}
              onChange={(e) => onChange("unableToProvideOTP", e.target.checked)}
              checked={isUnableToProvideOTP}
              typographyProps={{
                style: {
                  whiteSpace: "pre-line",
                }
              }}
            />
          </Grid>
          {isUnableToProvideOTP && (
            <Grid item>
              <CommonCheckbox
                label={t("agreeChangeOwner")}
                onChange={(e) => onChange("agreeChangeOwner", e.target.checked)}
                checked={field.value?.agreeChangeOwner}
                error={meta.error?.agreeChangeOwner && t(meta.error.agreeChangeOwner)}
              />
            </Grid>
          )}
        </Grid>

        <Grid item container>
          <Select
            id={`${name}.networkOperator`}
            placeholder={t("selectOption")}
            title={t("currentNetworkOperator")}
            options={SERVICE_PROVIDER_LIST.map((item) => ({...item, title: t(`existingNumberDialog.networkOperator.${item.value}`)}))}
            required
          />
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <YellowBubbleCheckbox
              label={t("postpaidService")}
              circle
              fullWidth
              checked={field.value?.simType === "postpaid"}
              onChange={() => helpers.setValue({ ...field.value, simType: "postpaid", realNameRegistered: undefined })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <YellowBubbleCheckbox
              label={t("prepaidSIM")}
              circle
              fullWidth
              checked={field.value?.simType === "prepaid"}
              onChange={() => helpers.setValue({ ...field.value, simType: "prepaid", realNameRegistered: true })}
            />
          </Grid>
        </Grid>

        {field.value?.simType === "postpaid" ? (
          // TNCItem(
          //   _.find(tncData, ["keyPath", "tnc.postPaid"]),
          //   "acceptedPostpaidTnc"
          // )
          null
        ) : (
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MyTypography>{t("realNameRegistered")}</MyTypography>
              </Grid>
              <Grid item xs={12} md={6}>
                <YellowBubbleCheckbox
                  label={t("common:yes")}
                  circle
                  fullWidth
                  checked={field.value?.realNameRegistered === true}
                  onChange={() => onChange("realNameRegistered", true)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <YellowBubbleCheckbox
                  label={t("common:no")}
                  circle
                  fullWidth
                  checked={field.value?.realNameRegistered === false}
                  onChange={() => onChange("realNameRegistered", false)}
                />
              </Grid>
            </Grid>
            {/* {field.value?.realNameRegistered && (
              TNCItem(
                _.find(tncData, ["keyPath", "tnc.postPaid"]),
                "acceptedPrepaidTnc"
              )
            )} */}
          </div>
        )}

        <DatePicker
          id={`${name}.effectiveDate`}
          title={t("effectiveDateTime")}
          minDate={dateRangeHelper.startDate}
          maxDate={dateRangeHelper.endDate}

          required
          shouldDisableDate={(date) => dateRangeHelper.shouldDisableDate(date)}
        />
        <Select
          id={`${name}.effectiveTime`}
          placeholder={t("selectOption")}
          options={MNP_EFFECTIVE_TIME_SLOT}
          required
        />
        <CommonCheckbox
          typographyProps={{ isHtml: true }}
          label={<TNCLabel />}
          onChange={(e) => onChange("acceptedPlanMnpTnc", e.target.checked)}
          checked={field.value?.acceptedPlanMnpTnc}
        />
        {isMnpFormRequired() &&
          <WarnText label={<>
            {t('existingNumberDialog.prepaidMNPFormPrefix')}
            <MyTextButton href={mnpDownloadFormUrl} target={"_blank"} style={{ margin: 0, padding: 0 }}>{t('existingNumberDialog.mnpForm')}</MyTextButton>
            {/* {field.value.unableToProvideOTP && <> / <MyTextButton href={changeOwnershipFormUrl} target="#" style={{ margin: 0, padding: 0 }}>{t('existingNumberDialog.changePassportForm')}</MyTextButton></>} */}
          </>
          }
          />
        }
        <Grid container item xs={12} justifyContent={"center"}>
          <MyButton
            style={{ width: isMobile ? "100%" : "80%" }}
            onClick={handleUpdateMnpNumberField}
            disabled={!isSaveButtonEnable}
          >
            {t("common:save")}
          </MyButton>
        </Grid>
      </Grid>

      {isOpenTNC.isOpen && (
        <SimpleDialog
          isOpen={isOpenTNC.isOpen}
          handleClose={() => setIsOpenTNC(DEFAULT_TNC)}
          title={GetWord(isOpenTNC?.title)}
        >
          <div
            dangerouslySetInnerHTML={{ __html: GetWord(isOpenTNC?.content) }}
          />
        </SimpleDialog>
      )}
    </SimpleDialog>
  );
};

export default ExistingNumberDialog;
