import styled from "styled-components";
import Option from "../../components/Option";
import Typography from "../../components/Typography";
import StepTitle from "../../components/Plan/StepTitle";
import Space from "../../components/Space";
import { Grid } from "@mui/material";
import Button from "../../components/Button";
import Divider from "@mui/material/Divider";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useField } from "formik";
import { breakpoint, pageMargin, WindowSize } from "../../utils/responsive";
import { GetWord } from "../../utils/common";
import { useRef, useState } from "react";
import { forwardRef } from "react";
import { FieldContainer } from "./styled";


const PageWrapper = styled.div`
  display: flex;
  justify-content: center;

  padding-left: 50px;
  padding-right: 100px;

  @media ${breakpoint.mobile} {
    padding: 0 30px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1420px;
  margin-left: 20px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  z-index: 1;

  @media ${breakpoint.desktop} {
    width: 100%;
    margin-top: 15px;
  }

  @media ${breakpoint.mobile} {
    width: calc(100% + 16px);
    margin-left: -60px;
    margin-top: 25px;
  }
`;

const Field = forwardRef(({
  title,
  id,
  options,
  stepId,
  steps,
  onTitleClick,
  multiple,
  isRequired,
  showTag = true,
  maxSelection,
  maxOptionShownCollapsed,
}, ref) => {
  const { t, i18n } = useTranslation(["mobile", "common"]);
  const isMobile = WindowSize() === "mobile";
  const [field, meta, helpers] = useField(id);
  const [isOptionsCollapsed, setIsOptionsCollapsed] = useState(true)
  const currentStep = _.find(steps, ["id", stepId]);

  const onOptionClick = (val) => {
    if (multiple) {
      if (field.value.includes(val)) {
        const newValue = field.value.filter((o) => o !== val);
        return helpers.setValue(newValue);
      } else if (maxSelection && field.value.length >= maxSelection) {
        if (maxSelection === 1) {   // for free vas, if maxSelection is 1, only one option can be selected --> treat as not multiple case
          helpers.setValue([val]);
        }
        return;
      }
      helpers.setValue([...field.value, val]);
    } else {
      helpers.setValue(val);
    }
  };

  return (
    <FieldContainer ref={ref}>
      <StepTitle
        title={title}
        stepList={steps}
        stepId={stepId}
        onClick={onTitleClick}
        currentStep={currentStep}
      />
      {currentStep?.isActive && (
        <PageWrapper>
          <Wrapper>
            <Divider orientation="vertical" flexItem />
            <Space width="40px" />

            <Content>
              <Grid container direction="row" spacing={2}>
                {options &&
                  options.map((item, idx) => {
                    const isRender = isOptionsCollapsed && !!maxOptionShownCollapsed ? idx < maxOptionShownCollapsed : true
                    const selected = multiple
                      ? field.value?.includes(item?._id)
                      : item?._id === field.value
                    return isRender && (
                      <Grid item xs={12} lg={6} key={item._id}>
                        <Option
                          onClick={onOptionClick}
                          selected={
                            selected
                          }
                          id={item?._id}
                          label={
                            item?.name
                              ? item?.name?.[i18n.language]
                              : item?.promotionTitle?.[i18n.language]
                          }

                          isRecommend={showTag && item?.isRecommended}
                          tagLabel={t('recommend')}
                          price={item.discountedPrice || 0}
                          priceUnit={item?.priceType && item?.priceType === "oneTime" ? undefined : t("monthPriceUnit")}
                          originalPrice={item.originalPrice}
                          detail={item?.detail}
                          tnc={item?.tncs?.[0]}
                          CheckboxProps={{
                            circle: !multiple || maxSelection === 1,
                            style: {
                              alignItems: 'flex-start'
                            },
                            typographyProps: {
                              style: {
                                marginTop: 6,
                                marginBottom: 6
                              }
                            }
                          }}
                          isBasePrice={stepId <= 1}
                        />
                      </Grid>
                    );
                  })}


              </Grid>
              <div
                style={{
                  display: "flex",
                  flexDirection: 'column',
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  marginTop: 30,
                  gap: 30
                }}
              >
                {options.length > maxOptionShownCollapsed &&
                  <Button
                    style={{ width: isMobile ? "100%" : 200, color: 'black', boxShadow: '0 3px 10px #c9c9c9' }}
                    color={"white"}
                    onClick={() =>
                      setIsOptionsCollapsed(!isOptionsCollapsed)
                    }
                  >
                    {isOptionsCollapsed ? t("button.showMore") : t("button.showLess")}
                  </Button>}
                <Button
                  color="black"
                  style={{ width: isMobile ? "100%" : 200 }}
                  onClick={() => {
                    onTitleClick(stepId + 1, isRequired, field.value)
                    
                    const selectedItem = multiple 
                                          ? options.filter((o) => field.value.includes(o._id) || field.value.includes(o.id))
                                          : options.find((o) => o._id === field.value || o.id === field.value)
                    steps?.[stepId-1]?.onNext && steps?.[stepId-1]?.onNext(selectedItem)
                  }}
                >
                  {t("common:nextStep")}
                </Button>
              </div>
            </Content>
          </Wrapper>
        </PageWrapper>
      )}
    </FieldContainer>
  );
})

export default Field;
