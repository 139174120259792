import React, { useRef, useMemo, useCallback } from 'react';

const InfiniteScroll = (props) => {
    const { 
        next,
        hasMore,
        isLoading,
        children,
        loadingElement,
    } = props;

    const observer = useRef();

    const lastElementRef = useCallback(
        (node) => {
            if (isLoading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    next && next();
                }
            });
            if (node) observer.current.observe(node);
        },
        [isLoading, hasMore, next]
    );

    const flatternChildren = useMemo(() => React.Children.toArray(children), [children]);

    return (
        <>
            {flatternChildren.map((child, index) => {
                if (flatternChildren.length === index + 1) {
                    return React.cloneElement(child, { ref: lastElementRef });
                }
                return child;
            })}
            {isLoading && loadingElement && (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                    {loadingElement}
                </div>
            )}
        </>
    );
};

export default InfiniteScroll;