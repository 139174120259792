import { useMemo } from "react";
import styled from "styled-components";
import theme from "../theme";
import Step from "./Step";
import { breakpoint } from "../utils/responsive";

function StepGroup({ data=[] }) {
  const activeStep = useMemo(() => data.findIndex((item) => item.isActive)+1 || 1, [data]);

  return (
    <Container>
      {data.map((item, idx) => {
        const isLastStep = idx === data?.length - 1;
        const stepId = idx+1;
        return (
          <StepItem>
            <Step bgColor={stepId <= activeStep ? theme.yellow : theme.divider}>
              {stepId}
            </Step>
            {!isLastStep && (
              <StyledDivider bgColor={stepId < activeStep ? theme.yellow : theme.divider} />
            )}
          </StepItem>
        );
      })}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 30px;
`;

const StepItem = styled.div`
  align-items: center;
  display: flex;
`;

const StyledDivider = styled.div`
  background-color: ${(props) => props.bgColor};
  height: 3px;
  @media ${breakpoint.mobile} {
    width: 40px;
    margin: 0px 5px;
  }

  @media ${breakpoint.desktop} {
    width: 70px;
    margin: 0px 10px;
  }
`;

export default StepGroup;
