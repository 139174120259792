import Typography from "../../../components/Typography";
import theme from "../../../theme";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ReactComponent as PrintIcon } from "../../../assets/ic-print.svg";
import { ResponsiveUI, WindowSize, breakpoint } from "../../../utils/responsive";
import OrderStatusField from "./OrderStatusField";
import OrderStatusTag from "./OrderStatusTag";
import { formatDate } from "../../../utils/common";

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 50px;
    row-gap: 10px;
    justify-content: space-between;

    @media ${breakpoint.desktop} {
        width: 100%;
    }

    @media ${breakpoint.mobile} {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
    }
`

const TitleInfoContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    column-gap: 100px;
    row-gap: 10px;
    flex-wrap: wrap;

    @media ${breakpoint.desktop} {
        width: 100%;
    }

    @media ${breakpoint.mobile} {
        row-gap: 20px;
    }
`

const TitleWordContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

export const TitleInfoField = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`

const CollapseTitleProp = (props) => {
    const { data, onClickPayAgain, onClickDownloadAF, isAfReady=false } = props;

    const { t } = useTranslation(['profile', 'common']);
    const isMobile = WindowSize() === 'mobile';

    const handleOnPrint = (e) => {
        e.stopPropagation();    // Prevent trigger collapse onClick event
        onClickDownloadAF && onClickDownloadAF();
    }

    const handleOnClickPayAgain = (e) => {
        e.stopPropagation();
        onClickPayAgain && onClickPayAgain();
    }

    const DateQtyField = ({ data }) => {
        return (
            <>
                <TitleInfoField style={{ flex: 2 }}>
                    <OrderStatusField
                        label={t('profile:orderDetails.orderNo')}
                        value={data?.orderNo}
                        status={data?.status}
                        onClickPayAgain={handleOnClickPayAgain}
                        onClickPrint={isAfReady ? handleOnPrint : undefined}
                    />
                </TitleInfoField>
                <TitleInfoField>
                    <Typography style={{ fontSize: '18px', color: theme.lightBlack }}>{t('profile:orderDetails.date')}</Typography>
                    <Typography style={{ fontSize: '20px', color: theme.lightBlack, fontWeight: 'bold' }}>{formatDate(data?.date, "DD/MM/YYYY HH:mm")}</Typography>
                </TitleInfoField>
                <TitleInfoField>
                    <Typography style={{ fontSize: '18px', color: theme.lightBlack }}>{t('profile:orderDetails.quantity')}</Typography>
                    <Typography style={{ fontSize: '20px', color: theme.lightBlack, fontWeight: 'bold' }}>{data?.quantity}</Typography>
                </TitleInfoField>
            </>
        )
    }

    return (
        <ResponsiveUI
            desktop={
                <TitleContainer>
                    <TitleInfoContainer>
                        <Typography style={{ fontSize: '24px', fontWeight: 'bold' }}>{t('profile:orderDetails.order', { index: data?.index })}</Typography>
                        <DateQtyField data={data} />
                    </TitleInfoContainer>
                </TitleContainer>
            }
            mobile={
                <TitleInfoContainer>
                    <TitleWordContainer>
                        <Typography style={{ fontSize: '24px', fontWeight: 'bold' }}>{t('profile:orderDetails.order', { index: data?.index })}</Typography>
                        <div><OrderStatusTag status={data?.status} style={{ flex: 1 }} /></div>
                    </TitleWordContainer>
                </TitleInfoContainer>
            }
        />
    )
}

export default CollapseTitleProp;