import { Trans } from 'react-i18next';
import { WindowSize } from '../../../utils/responsive';

const Title = () => {
  const isMobile = WindowSize() === 'mobile';

  return (
    <h2 style={{ textAlign: 'center', whiteSpace: 'normal' }}>
      <span>
        <Trans
          i18nKey={'home:dialogPreText'}
          components={{
            companyEmail: (
              <span style={{ fontSize: '32px', color: '#ffda11' }} />
            ),
            wbr: isMobile ? <br /> : <wbr />,
          }}
        />
      </span>
      <br />
      <span>
        <Trans i18nKey={'home:dialogPostText'} />
      </span>
    </h2>
  );
};

export default Title;
