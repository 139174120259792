import { isEmpty } from "lodash";
import MyTypography from "../components/Typography";
import dayjs from "dayjs";


export const scrollToHash = (id) => {
  document
    .getElementById(id)
    .scrollIntoView({ inline: "center", behavior: "smooth" });
};

export const GetWord = (word) => {
  let lang = localStorage.getItem("language");
  if (lang === "zh" || lang === "en") return word?.[lang];
  else return word?.["zh"];
};

export function formatCurrency(value = 0, precision = 1) {
  return value.toLocaleString("zh-HK", {
    minimumFractionDigits: value === 0 ? 0 : precision,
    maximumFractionDigits: precision,
  });
}

export function formatDate(date = null, format = "DD/MM/YYYY") {
  if (date === null || date === "" || date === undefined) return "—/—/——";
  return dayjs(date).format(format);
}

export function dec2hex(dec) {
  return dec.toString(16).padStart(2, "0");
}

export function genRandomString(len) {
  var arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join("");
}

export function isHtml(element) {
  return /<[a-z][\s\S]*>/i.test(element);
}

export const CommonPriceText = ({
  priceSize,
  currencySize,
  price,
  originalPrice,
}) => {
  return (
    <div>
      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <MyTypography fontSize={currencySize}>HK$</MyTypography>
        <MyTypography fontSize={priceSize} bold>
          {price}
        </MyTypography>
        <MyTypography fontSize={currencySize}>/月</MyTypography>
      </div>
      {originalPrice && (
        <MyTypography fontSize="11" color="#a7a7a7">
          原價 <del>HK${originalPrice}</del>
        </MyTypography>
      )}
    </div>
  );
};

export const fetchWithTimeout = async (fetcher, timeLimit) => {
  let timeoutHandle;

  const timeoutPromise = new Promise((_resolve, reject) => {
    timeoutHandle = setTimeout(
      () => reject(new Error("fetcher timeout call error")),
      timeLimit
    );
  });

  return Promise.race([fetcher, timeoutPromise]).then((result) => {
    clearTimeout(timeoutHandle);
    return result;
  });
};



export const disableSunday = (date) => {
  const day = date.day();

  return day === 0;
};

export const isValidForPayAgain = (status) => {
  return ["paymentFailure"].includes(status);
};

export const isValidOrder = (status) => {
  return !["pendingPayment", "paymentFailure"].includes(status);
}

export const isEmptyValues = (value) => {
  return (
    value === undefined ||
    value === null ||
    Number.isNaN(value) ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};

export const getItemColumnWidth = (type = "product") => {
  if (type === "plan") return ["45%", "11%", "11%", "11%", "11%", "11%"];
  if (type === "product") return ["48%", "26%", "10%", "16%"];
  if (["delivery", "discount"].includes(type)) return ["48%", "36%", "16%"]
  return ["45%", "20%", "15%", "20%"];
}

export const getCookie = (key) => {
  const item = document.cookie.match(`(^|;)\\s*${key}\\s*=\\s*([^;]+)`);
  return item ? item.pop() : undefined;
}

export const setCookie = (props) => {
  const { key, value, maxAge = undefined } = props;

  let cookieString = `${key}=${value}; path=/;`;
  if (maxAge) cookieString += ` max-age=${maxAge};`;

  document.cookie = cookieString;
}

// GA
export const getGAFormattedProduct = (item, showDiscount=false) => {
  const isPlan = !isEmpty(item?.planName?.en || item?.planName?.zh);

  let gaPayload;

  if (isPlan) {
    gaPayload = getGAFormattedPlan(item, showDiscount);
  } else {
    gaPayload = getGAFormattedDevice(item, showDiscount);
  }

  gaPayload = {
    ...gaPayload,
    index: !isNaN(item?.sort) ? item.sort : "NA",
    item_brand: GetWord(item?.brandName) || "NA",
    quantity: item?.quantity || 1,
    vas_id: item?.paidVas?.map((vas) => vas?.vasId || vas?._id) || "NA",
    freegift_id: item?.freeVas?.map((vas) => vas?.vasId || vas?._id) || "NA",
    recommended_product: item?.isAddedFromRecommend || false,
  }

  Array(10).fill().forEach((e, i) => {
    if (item?.paidVas?.[i]) {
      const { name, price, discountedPrice, originalPrice } = item?.paidVas?.[i];
      gaPayload[`vas_option${i + 1}`] = `${GetWord(name) || "NA"},${price || discountedPrice || originalPrice || 0}`
    } else {
      gaPayload[`vas_option${i + 1}`] = "NA";
    }

    if (item?.freeVas?.[i]) {
      const { name } = item?.freeVas?.[i];
      gaPayload[`freegift_option${i + 1}`] = `${GetWord(name) || "NA"}`
    } else {
      gaPayload[`freegift_option${i + 1}`] = "NA";
    }
  })

  return gaPayload;
}

export const getGAFormattedDevice = (item, showDiscount=false) => {
  let formattedProduct = {
    item_id: item?.productSku || item?.planId || item?.id || item?._id || "NA", // TODO
    item_name: GetWord(item?.productName) || "NA", // TODO
    item_category: item?.productCategory || "NA",
    price: item?.variant
      ? item?.variant?.sellingPrice || item?.variant?.markedPrice || item?.variant?.originalPrice || 0
      : item?.sellingPrice || item?.markedPrice || item?.originalPrice || 0,
    item_variant: item?.variant ? item?.variant?.options.map((i) => i.valueKey).toString() : "NA",
  }

  if (showDiscount) {
    const discount = item?.pricePerItem ? (item?.pricePerItem - item?.discountedPricePerItem) : (item?.variant?.markedPrice - formattedProduct?.price) || 0;
    formattedProduct["discount"] = discount && discount > 0 ? discount : 0;
  }

  return formattedProduct;
}

export const getGAFormattedPlan = (item, showDiscount=false) => {
  let formattedPlan = {
    item_id: item?.planId || item?.id || item?._id || "NA", // TODO
    item_name: GetWord(item?.planName) || "NA", // TODO
    item_category: "mobile_service",
    price: item?.variant
      ? item?.variant?.discountedPrice || item?.variant?.originalPrice || 0
      : item?.discountedPrice || item?.originalPrice || 0,
    item_variant: item?.variant ? item?.variant?.period : "NA",
    variation: item?.contractPeriodDetails?.length > 0 ? item?.contractPeriodDetails?.map((i) => i.period).toString(',') : "NA",
  }

  let numberSelect = [];

  if (item?.mnpNumbers?.length > 0) {
    item.mnpNumbers.forEach((number) => {
      numberSelect.push({ type: "mnp", ...number });
    });
  }

  if (item?.newNumbers?.length > 0) {
    item.newNumbers.forEach((number) => {
      numberSelect.push({ type: "new", ...number });
    });
  }

  if (numberSelect.length > 0) {
    formattedPlan["number_select"] = numberSelect.map((o) => o?.number).toString(',');

    Array(10).fill().forEach((item, index) => {
      const isItemExist = !isEmpty(numberSelect?.[index]);
      const { type, simType } = numberSelect?.[index] || {};

      formattedPlan[`plan_type_${index + 1}`] = isItemExist ? simType || "new_number" : "NA";
      formattedPlan[`port_in_${index + 1}`] = isItemExist ? (type === "mnp" ? "Yes" : "No") : "NA";
    })
  }

  return formattedPlan;
}

export const getGAFormattedProductList = (products = [], listInfo = {}, showDiscount = false) => {
  const payload = products.filter((item) => item?.type !== "delivery").map((product, index) => ({
    ...getGAFormattedProduct(product, showDiscount),
    index,
    ...listInfo
  }));

  return payload;
}

export const formatQuoteProductForGA = (item) => {
  const isPlan = item?.type === "plan";

  return {
    ...item?.product,
    ...item?.plan,
    type: item?.type,
    quantity: item?.quantity,
    freeVas: item?.options?.freeVas || [],
    paidVas: item?.options?.paidVas || [],
    variant: isPlan 
              ? item?.plan?.contractPeriodDetails?.find((i) => i._id === item?.options?.contractPeriodId) || "NA"
              : item?.options?.variationProduct || "NA",
    sort: item?.sort,
    discountedSubtotal: item?.discountedSubtotal,
    mnpNumbers: item?.mnpNumbers || [],
    newNumbers: item?.newNumbers || [],
    isAddedFromRecommend: item?.isAddedFromRecommend || false,
  }
}

export const getGATotalValue = (items) => {
  return items.reduce((acc, item) => {
    const { variant, sellingPrice, markedPrice, originalPrice, paidVas, quantity = 1 } = item;

    const price = variant ? variant?.sellingPrice || variant?.discountedPrice || variant?.markedprice || variant?.originalPrice : sellingPrice || markedPrice || originalPrice;
    const vasPrice = paidVas ? paidVas.reduce((acc, vas) => acc + (vas?.price || vas?.discountedPrice || vas?.originalPrice), 0) : 0;

    return acc + ((price + vasPrice) * quantity);
  }, 0);
}
