import LoadingIndicator from "../components/LoadingIndicator";
import { useContext, useEffect, useState } from "react";
import { retrievePaymentStatus } from "../apis";
import { appearGlobalError } from "../requests/globalRequest";
import { GlobalContextStore } from "../context/GlobalProvider";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { gaPurchase, gaTransactionFailed } from "../utils/ga";
import { gtmPurchase, gtmTransactionFailed } from "../utils/gtm";
import { formatQuoteProductForGA } from "../utils/common";
import { PROFILE_TABS, PROFILE_TAB_KEY } from "./Profile";

const ProcessingPayment = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const navigate = useNavigate();
  const query = useQuery();
  const { globalDispatch } = useContext(GlobalContextStore);
  const [repeater, setRepeater] = useState(0);
  const [maxRepeat, setMaxRepeat] = useState(10);

  const getProductGAPayload = (item) => {
    return {
      id: item?.product?.productSku,
      productName: item?.product?.name,
      sellingPrice: item?.discountedPricePerItem,
      markedPrice: item?.originalPricePerItem,
      quantity: item?.quantity,
      brandName: item?.product?.brandName,
      productCategory: item?.product?.productCategory,
      variant: {
        options: item?.product?.selectedOptions?.map((variantItem) => ({
          optionKey: variantItem?.option?.optionKey,
          valueKey: variantItem?.value?.value
        })) || [],
        markedPrice: item?.originalPricePerItem,
        sellingPrice: item?.discountedPricePerItem,
      },
      paidVas: item?.paidVas,
      freeVas: item?.freeVas,
      isAddedFromRecommend: item?.isAddedFromRecommend,
    }
  }

  const getPlanGAPayload = (item) => {
    return {
      ...item,
      ...item?.plan,
      planName: item?.plan?.name,
      variant: item?.plan?.contractPeriod,
    }
  }

  useEffect(() => {
    try {
      const fetcher = async () => {
        const body = { result: query.get("results") };
        const result = await retrievePaymentStatus(body, query.get("tid"));
        if(result?.msg === "success"){
          setRepeater(maxRepeat);
          if (result?.data?.transactionStatus === "SUCCESS") {
            // Handle GA
            const gaPayload = result?.data?.orderItems
                                .filter((item) => item.type !== "delivery")
                                .map((item) => 
                                  (item?.type === "plan") ? getPlanGAPayload(item) : getProductGAPayload(item)
                                );

            const transactionInfo = {
              transaction_id: result?.data?.orderNumber || "NA",
              tax: result?.data?.tax || 0,
              coupon: result?.data?.promoCode || "NA",
              shipping: result?.data?.deliveryFee || 0,
              value: (result?.data?.orderItemsTotalAmount || 0) - (result?.data?.promoCodeDiscountAmount || 0),
            }

            gaPurchase(gaPayload, transactionInfo);
            gtmPurchase(gaPayload, transactionInfo);

            // check contain plan type item 
            const isContainPlan = result?.data?.orderItems.some((item) => item?.type === "plan");
            if (isContainPlan) {
              localStorage.setItem("isAfterPayment", true); // for pop up hint dialog
            } else {
              localStorage.setItem(PROFILE_TAB_KEY, PROFILE_TABS.ORDER_DETAILS);
            }
          } else {
            gaTransactionFailed(result?.data?.transactionStatus);
            gtmTransactionFailed(result?.data?.transactionStatus);
          }
          navigate("/profile");
        }else{
          setTimeout(() => setRepeater((prevState) => prevState + 1), 5000);
        }
      };

      if (query.get("tid") && query.get("results")) {
        if (repeater < maxRepeat) {
          fetcher();
        } else {
          navigate("/profile");
        }
      } else {
        navigate("/profile");
        setRepeater(maxRepeat);
      }
    } catch (e) {
      console.log("process payment error - ", e);
      gaTransactionFailed(e?.errorMessage);
      gtmTransactionFailed(e?.errorMessage);
    }
  }, [repeater]);

  return <LoadingIndicator />;
};

export default ProcessingPayment;
