import { getRefreshToken, guestLoginIn, otpLogin, refreshAccessToken, requestLoginOtp } from './auth';
import axiosInstance from "./axiosInstance";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;







export const request = (param) => {
  return axiosInstance({
    ...param,
  })
    .then((res) => res.data)
    .catch(async (error) => {
      console.log("debug", error);
      throw error?.response?.data || error?.response?.status;
    });
};





const fetchHomeBannerList = (companyId) => {
  if (companyId) {
    return request({
      method: "get",
      url: `${API_ENDPOINT}/banner?page=1&limit=20&company=${companyId}`,
    });
  } else {
    return request({
      method: "get",
      url: `${API_ENDPOINT}/banner?page=1&limit=20`,
    });
  }
};

const fetchRecommendList = (companyId) => {
  if (companyId) {
    return request({
      method: "get",
      url: `${API_ENDPOINT}/recommend?&company=${companyId}`,
    });
  } else {
    return request({
      method: "get",
      url: `${API_ENDPOINT}/recommend`,
    });
  }
};

const fetchPlan = (companyId, page=1, limit=100) => {
  if (companyId) {
    return request({
      method: "get",
      // url: `${API_ENDPOINT}/plan?&company=${companyId}`,
      url: `${API_ENDPOINT}/plan/offer/company/${companyId}?page=${page}&limit=${limit}`,
    });
  } else {
    return request({
      method: "get",
      url: `${API_ENDPOINT}/plan?page=${page}&limit=${limit}`,
    });
  }
};

const fetchPlanById = (id) => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/plan/${id}`,
  });
};

const fetchDevice = ({companyId, page=1, limit=100, category}) => {
  if (companyId) {
    return request({
      method: "get",
      // url: `${API_ENDPOINT}/product?&company=${companyId}`,
      url: `${API_ENDPOINT}/product/offer/company/${companyId}?category=${category}&page=${page}&limit=${limit}`,
    });
  } else {
    return request({
      method: "get",
      url: `${API_ENDPOINT}/product?category=${category}&page=${page}&limit=${limit}`,
    });
  }
};

const fetchDeviceProduct = (id) => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/product/${id}`,
  });
};

const addToCart = (body) => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/cart`,
    data: body,
  });
};

const updateCart = (body) => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/cart`,
    data: body,
  });
};

const deleteCart = (id) => {
  return request({
    method: "delete",
    url: `${API_ENDPOINT}/cart/${id}`,
  });
};

const fetchCart = () => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/cart`,
  });
};

const fetchCartItem = (id) => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/cart/${id}`,
  });
};

const updateCartItem = (id, body) => {
  return request({
    method: "put",
    url: `${API_ENDPOINT}/cart/${id}`,
    data: body,
  });
};

const prepareCheckout = () => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/quote`,
  });
};

const fetchCheckout = () => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/quote`,
  });
};

const updateQuote = (body) => {
  return request({
    method: "put",
    url: `${API_ENDPOINT}/quote`,
    data: body,
  });
};

/**
 * @typedef {Object} AcceptedTncStatusValue
 * 
 * @property {string} tncId
 * @property {boolean} accepted
 */

/**
 * @typedef {Object} AcceptedTncStatusPayload
 * 
 * @property {AcceptedTncStatusValue[]} savePersonalInformation
 * @property {AcceptedTncStatusValue[]} directSaleThisServices
 * @property {AcceptedTncStatusValue[]} directSaleMyClubHKT
 * @property {AcceptedTncStatusValue[]} productTncs
 * @property {AcceptedTncStatusValue[]} planTncs
 * @property {AcceptedTncStatusValue[]} overallTncs
 */

/**
 * 
 * @param {AcceptedTncStatusPayload} body 
 * @returns 
 */
const updateQuoteTnc = (body) => {
  const x = body.directSaleMyClubHKT
  return request({
    method: "put",
    url: `${API_ENDPOINT}/quote/acceptTncs`,
    data: body,
  });
};

const processPayment = (body) => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/quote/checkout`,
    data: body,
  });
};

const addProfileAddress = (body) => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/user/address`,
    data: body,
  });
};

const updateProfileAddress = (body, id) => {
  return request({
    method: "put",
    url: `${API_ENDPOINT}/user/address/${id}`,
    data: body,
  });
};

const deleteAddress = (id) => {
  return request({
    method: "delete",
    url: `${API_ENDPOINT}/user/address/${id}`,
  });
};

const fetchLuckyNumber = () => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/numberPool`,
  });
};

const holdLuckyNumber = (body) => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/numberPool/holdNewNumbers`,
    data: body,
  });
};

const holdNewNumber = (body, id) => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/quote/addNewNumber/${id}`,
    data: body,
  });
};


const searchLuckyNumber = (body) => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/numberPool/lucky`,
    data: body,
  });
};

const requestPortinOtp = (body) => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/quote/requestPortInOtp`,
    data: body,
  });
};

const verifyPortinOtp = (body) => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/quote/verifyPortInOtp`,
    data: body,
  });
};

const addNumberToPlan = (body, id) => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/quote/addNumbers/${id}`,
    data: body,
  });
};






const otpLogout = () => {
  const refreshToken = getRefreshToken();
  return request({
    method: "post",
    url: `${API_ENDPOINT}/user/logout`,
    data: { refresh_token: refreshToken },
  });
};

const fetchUserInfo = (token) => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/user`,
  });
};

const updateUserInfoContent = (body) => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/user`,
    data: body,
  });
};

const sendContactUs = (body) => {
  return request({
    method: "post",
    data: body,
    url: `${API_ENDPOINT}/contactUs`,
  });
};

const fetchTutorial = () => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/tutorial`,
  });
};

const fetchUserOrders = () => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/order`,
  });
};

const fetchUserOrderById = (id) => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/order/${id}`,
  });
};

const fetchTNC = () => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/systemConfig/tnc`,
  });
};

const fetchDeliveryConfig = () => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/systemConfig/delivery`,
  });
};

const fetchCalendarConfig = () => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/systemConfig/publicHoliday`,
  });
};
const fetchNewNumberActivationConfig = () => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/systemConfig/plan/newNumberActivation`,
  });
};

const fetchDocUploadConfig = () => {
  console.log("fetching config")
  return request({
    method: "get",
    url: `${API_ENDPOINT}/systemConfig/docUpload`,
  });
}

const fetchMNPActivationHolidayConfig = () => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/systemConfig/plan/mnpActivationHolidays`,
  });
}

const retrievePaymentStatus = (body, id) => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/order/transaction/${id}`,
    data: body,
  });
};

const repurchaseByOrderId = (body) => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/cart/addFromOrder/`,
    data: body,
  });
};

const updateCoupon = (body) => {
  return request({
    method: "put",
    url: `${API_ENDPOINT}/quote/applyPromoCode`,
    data: body,
  });
}

const uploadDocument = (body) => {
  return request({
    method: "post",
    url: `${API_ENDPOINT}/order/upload`,
    data: body,
  })
}

const fetchSensitiveInfoById = (id) => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/order/${id}/view`,
  });
}

const deleteUserAllInfo = () => {
  return request({
    method: "delete",
    url: `${API_ENDPOINT}/user/info/deleteAll`,
  })
}

const fetchOrderAF = (orderId) => {
  return request({
    method: "get",
    url: `${API_ENDPOINT}/order/${orderId}/downloadAF`,
    responseType: 'arraybuffer',
  })
}



export {
  addNumberToPlan, addProfileAddress, addToCart, deleteAddress, deleteCart, deleteUserAllInfo, fetchCalendarConfig, fetchCart,
  fetchCartItem, fetchCheckout, fetchDeliveryConfig, fetchDevice,
  fetchDeviceProduct, fetchDocUploadConfig, fetchHomeBannerList, fetchLuckyNumber, fetchMNPActivationHolidayConfig, fetchNewNumberActivationConfig, fetchOrderAF, fetchPlan,
  fetchPlanById, fetchRecommendList, fetchSensitiveInfoById, fetchTNC, fetchTutorial, fetchUserInfo, fetchUserOrderById, fetchUserOrders, guestLoginIn, holdLuckyNumber,
  holdNewNumber, otpLogin, otpLogout, prepareCheckout, processPayment, refreshAccessToken, repurchaseByOrderId, requestLoginOtp, requestPortinOtp, retrievePaymentStatus, searchLuckyNumber, sendContactUs, updateCart,
  updateCartItem, updateCoupon, updateProfileAddress, updateQuote,
  updateQuoteTnc, updateUserInfoContent, uploadDocument, verifyPortinOtp
};

