import { useContext, useMemo } from "react"
import { CheckoutFormContext } from "../context/CheckoutFormContext"
import { TncContext } from "../context/GlobalTncContext"
import { ACCEPTED_TNCS_KEYS } from "./useCheckoutInitialRequiredTncs"

/**
 * a custom hook that wrap around the tncContext 
 * 
 * depends on the tncContext and checkoutFormContext
 * @returns 
 */
const useCheckoutGlobalTnc = () => {
  const tncCtx = useContext(TncContext)
  const checkoutFormCtx = useContext(CheckoutFormContext)
  const { acceptedTNCStatus, setAcceptedTNCStatus } = tncCtx
  const { tncQuery, hasPlan } = checkoutFormCtx


  if (!tncCtx || !checkoutFormCtx) {
    throw new Error('useCheckoutGlobalTnc must be used within a CheckoutFormProvider and TncProvider')
  }

  /**
   * @type {Array} directedSalesTncs
   */
  const directedSalesTncs = useMemo(() => {
    const requiredTncs = hasPlan ? ["tnc.directSaleThisServices", "tnc.directSaleMyClubHKT"] : ["tnc.directSaleMyClubHKT"]
    return tncQuery?.data?.data?.filter((tnc) => {
      return (
        requiredTncs.includes(tnc.keyPath) &&
        tnc.value
      );
    }).sort((a, b) => {
      // TODO: re-order without sorting the array
      if (a.keyPath === "tnc.directSaleThisServices") return -1;
      else return 1;
    });
  }, [tncQuery?.data?.data, hasPlan])

  /**
   * Check all directSaleThisServices tncs are accepted
   * @type {boolean} isSelectedTncsAccepted
   */
  const isDirectSaleTncAccepted = useMemo(() => {
    const { directSaleThisServices } = acceptedTNCStatus || {
      directSaleThisServices: null,
    }

    return directSaleThisServices?.every((item) => item.accepted)

  }, [acceptedTNCStatus])

  const allRequiredPicsTncsAccepted = isDirectSaleTncAccepted

  return {
    acceptedTNCStatus,
    setAcceptedTNCStatus,
    directedSalesTncs,
    allRequiredPicsTncsAccepted,
    isDirectSaleTncAccepted
  }

}

export default useCheckoutGlobalTnc