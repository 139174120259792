import { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Grid, Divider } from "@mui/material";
import PlanCard from "../components/ProductCard/PlanCard";
import PlanDetailCard from "../components/ProductCard/PlanDetailCard";
import PageHeader from "../components/Header";
import Typography from "../components/Typography";
import StepGroup from "../components/StepGroup";
import Form from "../pageComponents/mobile/Form";
import InfoButton from "../components/Plan/InfoButton";
import { useTranslation } from "react-i18next";
import { ResponsiveUI, breakpoint } from "../utils/responsive";
import {
  fetchCartItem,
  fetchPlanById,
  addToCart,
  updateCartItem,
} from "../apis";
import { useMutation, useQueries } from "@tanstack/react-query";
import LoadingIndicator from "../components/LoadingIndicator";
import {
  useParams,
  Navigate,
  useSearchParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Space from "../components/Space";
import { WindowSize } from "../utils/responsive";
import { GetWord } from "../utils/common";
import PageHelmet from "../components/Helmet";
import { GlobalContextStore } from "../context/GlobalProvider";
import { appearGlobalError } from "../requests/globalRequest";
import { gaAddToCart, gaSelectContractPeriod, gaSelectPlanFreeVas, gaSelectPlanPaidVas, gaViewItem } from "../utils/ga";
import { gtmAddToCart, gtmSelectContractPeriod, gtmSelectPlanFreeVas, gtmSelectPlanPaidVas, gtmViewItem } from "../utils/gtm";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled(Grid)`
  && {
    height: 100%;
  }
`;
const FormContainer = styled(Grid)``;
const FormHeader = styled(Grid)`
  && {
    padding: 30px 40px;
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${breakpoint.desktop} {
    margin: 40px 0px;
    width: 100%;
    max-width: 1440px;
  }

  @media ${breakpoint.mobile} {
    margin-top: -25px;
  }
`;

const PlanCardContainer = styled.div`
  @media ${breakpoint.desktop} {
    margin-top: -100px;
    margin-left: 100px;
    margin-right: -50px;
  }
  @media ${breakpoint.mobile} {
    display: flex;
    justify-content: center;
  }
`;

const STEP_LIST = [
  {
    id: 1,
    isActive: true,
    isCompleted: false,
    onNext: (period) => {
      gaSelectContractPeriod(period?.period);
      gtmSelectContractPeriod(period?.period);
    }
  },
  {
    id: 2,
    isActive: false,
    isCompleted: false,
    onNext: (items) => {
      const vasName = items?.length > 0 
                        ? items.map((item) => GetWord(item?.name)).join(",") 
                        : "NA";
      gaSelectPlanFreeVas(vasName);
      gtmSelectPlanFreeVas(vasName);
    }
  },
  {
    id: 3,
    isActive: false,
    isCompleted: false,
    onNext: (items) => {
      const vasName = items?.length > 0 
                        ? items.map((item) => GetWord(item?.name)).join(",") 
                        : "NA";
      gaSelectPlanPaidVas(vasName);
      gtmSelectPlanPaidVas(vasName);
    }
  },
  {
    id: 4,
    isActive: false,
    isCompleted: false,
  },
];

const Mobile = () => {
  const { t } = useTranslation("mobile");
  const { id } = useParams();
  const navigate = useNavigate();

  const location = useLocation();
  const state = location.state || {};
  const { isRecommend=false } = state;    // from cart recommend product list section

  const [searchParams, setSearchParams] = useSearchParams();
  const editId = searchParams.get("editId");

  const { globalState, globalDispatch } = useContext(GlobalContextStore);
  const [steps, setSteps] = useState(STEP_LIST);
  const isMobile = WindowSize() === "mobile";

  const [planData, cartPayload] = useQueries({
    queries: [
      {
        queryKey: ["plan", id],
        queryFn: () => fetchPlanById(id),
      },
      {
        queryKey: ["cartId", editId],
        queryFn: () => {
          if (!editId) return;
          return fetchCartItem(editId);
        },
      },
    ],
  });

  const getPlanGAPayload = (data) => {
    const { plan, options, quantity } = data;
    
    const payload = {
      ...plan,
      quantity,
      variant: plan?.contractPeriodDetails?.find((item) => item?._id === options?.contractPeriodId),
      freeVas: options?.freeVas,
      paidVas: options?.paidVas,
      isAddedFromRecommend: cartPayload?.data?.data?.isAddedFromRecommend || isRecommend,
    }

    return payload;
  }

  const addToCartMutation = useMutation({
    mutationFn: (payload) => {
      return editId 
        ? updateCartItem(editId, payload) 
        : addToCart({ ...payload, isAddedFromRecommend: isRecommend })
    },
    onSuccess: (data) => {
      const gaPayload = getPlanGAPayload(data?.data?.items?.[0]);
      gaAddToCart(gaPayload);
      gtmAddToCart(gaPayload);

      navigate("/shoppingcart");
    },
    onError: (error) => {
      console.log("error", error);
      appearGlobalError(globalDispatch, error?.code, error?.errorMessage, { type: "fail" });
    }
  })

  const handleAddToCart = async (payload) => {
    addToCartMutation.mutate(payload);
  };

  useEffect(() => {
    if (planData?.data?.data) {
      if (editId && !cartPayload?.data?.data) return;

      const gaPayload = { 
        ...planData?.data?.data,
        isAddedFromRecommend: cartPayload?.data?.data?.isAddedFromRecommend || isRecommend,
      };
      gaViewItem(gaPayload);
      gtmViewItem(gaPayload);
    }
  }, [planData?.data?.data, cartPayload?.data?.data])

  if (planData?.isLoading) {
    return <LoadingIndicator />;
  }

  if (planData?.isError || planData?.data?.result?.err) {
    // console.log("error - ", planData?.isError);
    return <Navigate to="/404" />;
  }

  return (
    <Container>
      <PageHelmet title={GetWord(planData?.data?.data?.planName)} />
      <PageHeader>{t("mobile")}</PageHeader>
      <PageContainer>
        <Grid container direction="column">
          <Content container direction="row">
            <Grid item xs={12} md="auto">
              <PlanCardContainer>
                <PlanDetailCard
                  // style={{ position: "relative", top: -60 }}
                  data={planData?.data?.data}
                />
              </PlanCardContainer>
            </Grid>
            <Grid item xs>
              <FormContainer container direction="column">
                <ResponsiveUI
                  mobile={
                    <>
                      <Space size="70px" /><div style={{ width: '100%', background: 'white', position: 'sticky', top: globalState.cookieConsentHeight, paddingTop: '30px', zIndex: 10 }}>
                        <StepGroup data={steps} />
                      </div>
                    </>}
                  desktop={
                    <StepGroup data={steps} />}
                />

                <Divider />
                <div style={isMobile ? { position: 'relative' } : undefined}>
                  <Form
                    setSteps={setSteps}
                    steps={steps}
                    data={planData?.data?.data}
                    selected={cartPayload?.data?.data}
                    onSubmit={handleAddToCart}
                    isSubmitLoading={addToCartMutation.isLoading}
                  /></div>
              </FormContainer>
            </Grid>
          </Content>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              margin: isMobile ? "50px 30px" : "50px 100px",
            }}
          >
            {planData?.data?.data?.detailPageTncs?.length > 0 && (
              <InfoButton
                label={t("termsAndConditions")}
                title={planData?.data?.data?.detailPageTncs[0]?.title}
                content={planData?.data?.data?.detailPageTncs[0]?.description}
              />
            )}
            {planData?.data?.data?.remark && (
              <InfoButton
                label={t("remark")}
                title={{ en: "Remark", zh: "備註" }}
                content={planData?.data?.data?.remark}
              />
            )}
          </div>
        </Grid>
      </PageContainer>
    </Container>
  );
};

export default Mobile;
