import { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { useInfiniteQuery, useQueries } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Grid } from '@mui/material';

import SwitchTab from '../../components/Tab/SwitchTab';
import DeviceCard from '../../components/ProductCard/DeviceCard';
import PlanCard from '../../components/ProductCard/PlanCard';
import PageHelmet from '../../components/Helmet';
import { MOBILE_NAVBAR_HEIGHT } from '../../components/NavBar/constant';
import LoadingIndicator from '../../components/LoadingIndicator';
import InfiniteScroll from '../../components/InfiniteScroll';

import { breakpoint, WindowSize } from '../../utils/responsive';
import { scrollToHash } from '../../utils/common';
import { gaSelectItem, gaViewItemList } from '../../utils/ga';
import { gtmSelectItem, gtmViewItemList } from '../../utils/gtm';

import theme from '../../theme';
import { fetchDevice, fetchPlan } from '../../apis';

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

const PlanList = ({ recommendList, companyId }) => {
  const { t } = useTranslation('home');
  let navigate = useNavigate();

  const TABS = [
    { label: t('tab.mobile'), value: 'mobile' },
    { label: t('tab.apple'), value: 'apple' },
    { label: t('tab.android'), value: 'android' },
    // { label: t("accessories"), value: "accessoriesGaming" },
    { label: t('tab.lifestyle'), value: 'homeLifestyle' },
  ];

  const query = useQuery();
  const serviceQuery = useMemo(() => query.get('service'), [query]);

  const isMobile = WindowSize() === 'mobile';
  const [selected, setSelected] = useState(serviceQuery || undefined);

  const limit = 12;
  const isDeviceCategory = selected !== 'mobile';

  const planQuery = useInfiniteQuery({
    queryKey: ['planList', companyId],
    queryFn: ({ pageParam = 1 }) => fetchPlan(companyId, pageParam, limit),
    enabled: selected !== undefined && !isDeviceCategory,
    getNextPageParam: (lastPage) => {
      const { page, totalPages } = lastPage || {};
      return page < totalPages ? page + 1 : undefined;
    },
  });

  const deviceQuery = useInfiniteQuery({
    queryKey: ['deviceList', companyId, selected],
    queryFn: ({ pageParam: page = 1 }) => fetchDevice({category: selected, companyId, page, limit}),
    enabled: selected !== undefined && isDeviceCategory,
    getNextPageParam: (lastPage) => {
      const { page, totalPages } = lastPage || {};
      return page < totalPages ? page + 1 : undefined;
    },
  })

  const activeQuery = useMemo(() => (isDeviceCategory ? deviceQuery : planQuery), [isDeviceCategory, deviceQuery, planQuery]);
  const data = useMemo(() => activeQuery?.data?.pages?.flatMap((page) => page?.data) || [], [activeQuery]);

  useEffect(() => {
    if (serviceQuery) {
      if (TABS.map((tab) => tab.value).includes(serviceQuery)) {
        scrollToHash('planList');
        setSelected(serviceQuery);
      }
    } else {
      setSelected(TABS?.[0]?.value);
    }
  }, [serviceQuery]);

  useEffect(() => {
    if (selected && data.length > 0) {
      const listInfo = {
        item_list_id: companyId || 'NA',
        item_list_name: selected, // TODO: confirm use selected or company name
      };

      gaViewItemList(data, listInfo);
      gtmViewItemList(data, listInfo);
    }
  }, [data]);

  const handleClickItem = (item, type, pos) => {
    const { freeVas, paidVas, ...rest } = item;
    const gaPayload = { ...rest, sort: pos };
    gaSelectItem(gaPayload);
    gtmSelectItem(gaPayload);

    navigate(`/${type}/${item?._id}`);
  };

  return (
    <Container>
      {serviceQuery && (
        <PageHelmet title={TABS.find((item) => item.value === selected).label.replace('\n','')} />
      )}
      <Wrapper>

        {/* DIV for scrolling */}
        <div
          id="planList"
          style={{ scrollMarginTop: isMobile ? MOBILE_NAVBAR_HEIGHT : '126px' }}
        />

        {/* Category Selection */}
        <SwitchTab
          style={{ marginBottom: 60 }}
          data={TABS}
          setSelected={(index) => setSelected(TABS?.[index]?.value)}
          selected={TABS.findIndex((tab) => tab.value === selected)}
          navigate={navigate}
        />

        {/* Product List */}
        {
          (activeQuery?.isLoading) ? (
            <LoadingIndicator />
          ) : (
            <Grid 
              container 
              justifyContent="center"
              columnSpacing={7}
              rowSpacing="36px"
            >
              <InfiniteScroll
                next={activeQuery.fetchNextPage}
                hasMore={activeQuery.hasNextPage}
                isLoading={activeQuery.isFetching}
                loadingElement={<CircularProgress style={{ color: theme.yellow, marginTop: 30 }} />}
              >
                {data.map((item, index) => (
                  <PlanCardContainer item key={`${selected}-item-${item?._id}`}>
                    {isDeviceCategory ? (
                      <DeviceCard
                        data={item}
                        handleClick={() => handleClickItem(item, 'device', index)}
                        style={{ alignSelf: 'flex-end' }}
                        enlargedIcon={true}
                      />
                    ) : (
                      <PlanCard
                        type="animation"
                        data={item}
                        handleClick={() => handleClickItem(item, 'mobile', index)}
                      />
                    )}
                  </PlanCardContainer>
                ))}
              </InfiniteScroll>
            </Grid>
          )
        }
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 70px 0px 100px 0px;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: ${theme.maxWidth.desktop};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PlanCardContainer = styled(Grid)`
  display: flex;
  align-items: end;

  @media ${breakpoint.mobile} {
    && {
      margin-bottom: 60px;
    }
  }

  @media ${breakpoint.desktop} {
    // && {
    //   margin-right: 75px;
    // }
  }
`;

export default PlanList;
