import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useFormik, FormikProvider, Form } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import MyTextField from "../../components/TextField";
import MyButton from "../../components/Button";
import Space from "../../components/Space";
import { GetWord } from "../../utils/common";

const LoginOTP = ({ onSubmit, onResend, isLoading=false }, ref) => {
  const { t } = useTranslation("login");

  const [resendOtpCooldown, setResendOtpCooldown] = useState(0);

  const formik = useFormik({
    initialValues: { oneTimePassword: "" },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      oneTimePassword: Yup.string().required(t("errorInvalidInput")),
    }),
    onSubmit: async (payload) => {
      console.log("submit otp", payload);
      onSubmit && onSubmit(payload);
    },
  });

  const handleSubmit = () => {
    formik.handleSubmit();
  }

  const handleClickResent = () => {
    if (resendOtpCooldown > 0) return;
    onResend && onResend();
  }

  useEffect(() => {
    if (resendOtpCooldown > 0) {
      const interval = setInterval(() => {
        setResendOtpCooldown((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [resendOtpCooldown]);

  useImperativeHandle(ref, () => {
    return {
      setErrors: formik.setErrors,
      setResendOtpCooldown,
    }
  }, [formik]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <MyTextField
          id="oneTimePassword"
          placeholder={t("otpPlaceholder")}
          title={t("oneTimePassword")}
          helperText={GetWord(formik.errors.oneTimePassword)}
          onChange={(e) => {
            formik.setErrors({ oneTimePassword: "" });
            formik.setFieldValue("oneTimePassword", e.target.value);
          }}
          style={{
            minHeight: "80px",
          }}
        />
        <Space size="22px" />
        <MyButton
          onClick={handleClickResent}
          type="secondary"
          size={"medium"}
          fullWidth
          fontSize={16}
          disabled={isLoading || resendOtpCooldown > 0}
        >
          {`${t("resendOtp")}${resendOtpCooldown > 0 ? ` (${resendOtpCooldown})` : ""}`}
        </MyButton>
        <Space size="22px" />
        <MyButton
          onClick={handleSubmit}
          size={"medium"}
          fullWidth
          fontSize={16}
          disabled={isLoading}
        >
          {t("continue")}
        </MyButton>
      </Form>
    </FormikProvider>
  );
};

export default forwardRef(LoginOTP);
