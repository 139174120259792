import * as Yup from "yup";

export const getMobileNumYUP = () => {
  return Yup.number()
    .typeError("error:invalidMobile")
    .test("len", "error:invalidMobile", (val) => val.toString().length === 8)
    .required("error:required");
};

export const getOnlyEnglishStringYUP = () => {
  const reg = /^[A-Za-z\s]+$/;
  return Yup.string()
    .matches(reg, "error:onlyEnglishLetters")
    .required("error:required");
};

export const getOnlyChineseEnglishStringYUP = () => {
  const reg = /[\u4e00-\u9fa5a-zA-Z]/g;

  return Yup.string()
    .matches(reg, "error:onlyChineseOrEnglishLetters")
    .required("error:required");
};

export const getOnlyChineseStringYUP = () => {
  // TODO: include space character?
  const reg = /^[\u4e00-\u9fa5]+$/;

  return Yup.string()
    .matches(reg, "error:onlyChineseLetters")
    // .required("error:required");
}


