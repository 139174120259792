import TagManager from 'react-gtm-module';
import { getGAFormattedProduct, getGATotalValue, getGAFormattedProductList } from "./common";

export const getGTMCode = () => {
    return 'GTM-MKQZM2XV';
}

export const initGTM = () => {
    TagManager.initialize({ 
        gtmId: getGTMCode(),
    });
}

const getEventName = (event_name) => {
    const eventPrefix = '';
    return eventPrefix + event_name;
}

const gtmEvent = (event_name, params=undefined) => {
    TagManager.dataLayer({
        dataLayer: {
            event: getEventName(event_name),
            ...params,
        }
    })
}

export const gtmNavigationBar = (data) => {
    gtmEvent('navigation_bar', {...data});
}

export const gtmSocialClick = (data) => {
    gtmEvent('social_click', {...data});
}

export const gtmProductCategoryClick = (data) => {
    gtmEvent('product_categories_click', {...data});
}

export const gtmSignUpSubmit = () => {
    gtmEvent('signup_submit');
}

export const gtmSignUpFail = () => {
    gtmEvent('signup_fail');
}

export const gtmEnterLoginEmail = () => {
    gtmEvent('enter_login_email');
}

export const gtmLoginClick = () => {
    gtmEvent('login_click');
}

export const gtmLoginFail = () => {
    gtmEvent('login_fail');
}

export const gtmOutOfStock = (data) => {
    gtmEvent('out_of_stock_ok', {...data});
}

// Ecormmerce related GA events
const gtmEcommerceEvent = (event_name, data) => {
    const payload = {
        ...data,
        currency: 'HKD',
        value: data?.items ? data?.items.reduce((acc, item) => acc + item.price * item?.quantity || 1, 0) : 0,
        items: data?.items && data.items.length > 0 ? data?.items : []
    }

    if (data?.value) {
        payload.value = data.value;
    }

    gtmEvent(event_name, { ecommerce: payload });
}

export const gtmApplyPromoCode = (Promo_code) => {
    gtmEcommerceEvent('apply_promocode', { Promo_code });
}

export const gtmViewItemList = (products=[], listInfo) => {
    const payload = getGAFormattedProductList(products, listInfo);
    gtmEcommerceEvent('view_item_list', {
        value: getGATotalValue(products),
        items: payload,
        ...listInfo,
    });
}

export const gtmSelectItem = (product) => {
    const payload = getGAFormattedProduct(product);
    gtmEcommerceEvent('select_item', { 
        value: getGATotalValue([product]),
        items: [payload],
    });
}

export const gtmViewItem = (product) => {
    const payload = getGAFormattedProduct(product);
    gtmEcommerceEvent('view_item', { 
        value: getGATotalValue([product]),
        items: [payload],
    });
}

export const gtmAddToCart = (product) => {
    const payload = getGAFormattedProduct(product);
    gtmEcommerceEvent('add_to_cart', { 
        value: getGATotalValue([product]),
        items: [payload],
    });
}

export const gtmViewCart = (products) => {
    const payload = getGAFormattedProductList(products, undefined, true);
    gtmEcommerceEvent('view_cart', { 
        value: getGATotalValue(products),
        items: payload,
        coupon: "NA",
    });
}

export const gtmRemoveFromCart = (product) => {
    const payload = getGAFormattedProduct(product);
    gtmEcommerceEvent('remove_from_cart', { 
        value: product?.discountedSubtotal,
        items: [payload],
    });
}

export const gtmBeginCheckout = (products, coupon="NA") => {
    const payload = getGAFormattedProductList(products, undefined, true);
    gtmEcommerceEvent('begin_checkout', { 
        value: getGATotalValue(products),
        items: payload,
        coupon,
    });
}

export const gtmAddShippingInfo = (products) => {
    const payload = getGAFormattedProductList(products, undefined, true);
    gtmEcommerceEvent('add_shipping_info', { 
        value: getGATotalValue(products),
        items: payload,
        coupon: "NA",
    });
}

export const gtmAddPaymentInfo = (products, coupon="NA") => {
    const payload = getGAFormattedProductList(products, undefined, true);
    gtmEcommerceEvent('add_payment_info', { 
        value: getGATotalValue(products),
        items: payload,
        coupon,
        payment_type: "credit_card",
    });
}

export const gtmPurchase = (products, transactionInfo) => {
    const payload = getGAFormattedProductList(products, undefined, true);
    gtmEcommerceEvent('purchase', {
        value: getGATotalValue(products),
        items: payload,
        ...transactionInfo,
    });
}

export const gtmContinueShopping = (products) => {
    const payload = getGAFormattedProductList(products, undefined, true);
    gtmEcommerceEvent('continue_shopping', { 
        value: getGATotalValue(products),
        items: payload,
        coupon: "NA",
    });
}

// Ecommerce related GA events (banner/promotion)
export const gtmSelectPromotion = (value) => {
    gtmEcommerceEvent('select_promotion', {
        items: [],
        value,
    });
}

export const gtmViewPromotion = (value) => {
    gtmEvent('view_promotion', {
        items: [],
        value,
    });
}

// SIM plan GA
export const gtmLoginPopup = (page_url, destination_page_url=page_url) => {
    gtmEvent("log_in_pop_up", {
        page_url,
        destination_page_url,
    })
}

export const gtmViewServicePlan = (planName="NA") => {
    gtmEvent("view_service_plan", {
        item_viewed: planName,
    });
}

export const gtmSelectContractPeriod = (period="NA") => {
    gtmEvent("select_contract_period", {
        contract_period_selected: period,
    });
}

export const gtmSelectPlanFreeVas = (items="NA") => {
    gtmEvent("select_free_vas", {
        free_VAS_selected: items,
    });
}

export const gtmSelectPlanPaidVas = (items="NA") => {
    gtmEvent("select_paid_vas", {
        paid_VAS_selected: items,
    });
}

export const gtmSelectNewNumber = (numbers="NA") => {
    gtmEvent("select_new_number", {
        number_selected: numbers,
    })
}

export const gtmSelectPortInNumber = ({plan_type="NA", RNR="Yes"}) => {
    gtmEvent("select_port_in_number", {
        plan_type,
        RNR,
    });
}

export const gtmSelectAutoRenewal = () => {
    gtmEvent("select_auto_renewal");
}

export const gtmTransactionFailed = (fail_reason="NA") => {
    gtmEvent("transaction_failed", {
        fail_reason,
    });
}

export const gtmViewDetails = (itemName) => {
    gtmEvent("view_details", {
        item_viewed: itemName,
    });
}