import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import DevicePriceBar from '../../../components/BottomBar/DevicePriceBar';
import MyButton from '../../../components/Button';
import Space from '../../../components/Space';

import { usePreviewOrderTnc } from '../hooks/usePreviewOrderTnc';
import { breakpoint } from '../../../utils/responsive';

const getInitialValues = (data) => {
  const { userInfo, contactUserInfo, deliveryInfo } = data?.userForm;
  const initialValues = {
    order_list: data?.items || [],
    customer_title: userInfo?.title || '',
    customer_engName: userInfo?.fullName?.en || '',
    customer_chiName: userInfo?.fullName?.zh || '',
    customer_idType: userInfo?.idType || '',
    customer_idNum: userInfo?.idNumber || '',
    customer_dateOfBirth: userInfo?.birthday || '',
    customer_companyName: userInfo?.companyName || '',
    customer_jobPosition: userInfo?.companyPosition || '',
    customer_email: userInfo?.email || '',
    customer_mobileNo: userInfo?.mobileNumber || '',
    customer_address1: userInfo?.mailingAddress[0] || '',
    customer_address2: userInfo?.mailingAddress[1] || '',
    customer_district: userInfo?.mailingDistrict || '',
    contact_title: contactUserInfo?.title || '',
    contact_engName: contactUserInfo?.fullName?.en || '',
    contact_chiName: contactUserInfo?.fullName?.zh || '',
    contact_email: contactUserInfo?.email || '',
    contact_mobileNo: contactUserInfo?.mobileNumber || '',
    delivery_method: deliveryInfo?.deliveryMethod || 'pickup',
    delivery_addressId: deliveryInfo?.deliveryAddressSelectedId || '',
    delivery_address1: deliveryInfo?.deliveryAddress[0] || '',
    delivery_address2: deliveryInfo?.deliveryAddress[1] || '',
    delivery_district: deliveryInfo?.deliveryDistrict || '',
    delivery_date: deliveryInfo?.deliveryDate || '',
    delivery_time: deliveryInfo?.deliveryTime || '',
    payment_method: 'creditCard',
    tnc_accept_status: {},
  };

  return initialValues;
};


/**
   * 
   * @param {string[]} requiredTncIds 
   * @returns 
   */
const createValidationSchema = (requiredTncIds) => {
  const validationObject = {};
  requiredTncIds.forEach((id) => {
    validationObject[id] = Yup.boolean().required().oneOf([true], 'error:required');
  });
  return Yup.object().shape({
    tnc_accept_status: Yup.object().shape(validationObject),
  });
}

const PreviewForm = ({ data: quoteData, children, onBack, onSubmit: onCheckout, isLoading=false, }) => {
  const { t } = useTranslation('checkout');

  const { isPreviewRequiredTncsAccepted } = usePreviewOrderTnc();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(quoteData),
    onSubmit: (payload, { setErrors }) => {

      if (!isPreviewRequiredTncsAccepted) {
        setErrors({ accepted_tnc: "error:requiredAcceptTnc" });
        const element = document.getElementsByName('accepted_tnc')[0];
        return element.scrollIntoView({ behavior: 'smooth', block: "start" });
      }
      
      onCheckout && onCheckout();
    },
    // validationSchema: createValidationSchema(productOverallTncIds),
  });

  // useEffect(() => {
  //   const newStatus = {}
  //   overallTncStatus.forEach((item) => {
  //     newStatus[item.tncId] = item.accepted;
  //   })
  //   formik.setFieldValue('tnc_accept_status', newStatus);
  // }, [overallTncStatus])

  return (
    <FormikProvider value={formik}>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Form style={{ maxWidth: '1440px', overflowX: "hidden" }}>
          <Container>{children}</Container>

          <PaymentButton>
            <MyButton type="secondary" onClick={onBack} disabled={isLoading}>
              {t('back')}
            </MyButton>
            <Space width="20px" />
            <MyButton onClick={() => formik.submitForm()} disabled={isLoading}>
              {t('payment')}
            </MyButton>
          </PaymentButton>

          <DevicePriceBar
            totalPrice={quoteData?.calculatedOrderPrice}
            onClick={() => formik.submitForm()}
            isCheckout
            titleShowTotal
            disabled={isLoading}
          />
        </Form>
      </div>
    </FormikProvider>
  );
};

const Container = styled.div`
  margin: 0 50px;

  @media ${breakpoint.mobile} {
    margin: 0 35px;
  }
`;

const PaymentButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 50px;

  @media ${breakpoint.mobile} {
    margin: 35px;
    flex-direction: column;
  }
`;

export default PreviewForm;
