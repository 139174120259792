import { useState, useContext, useRef } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import theme from "../theme";
import Typography from "./Typography";
import { otpLogin, requestLoginOtp } from "../apis";
import ImageLoginBg from "../assets/image-loginBg.png";
import ImageLoginBgMobile from "../assets/image-loginBgMobile.png";
import CheckEmail from "../pageComponents/login/CheckEmail";
import LoginOTP from "../pageComponents/login/LoginOTP";
import { appearGlobalError, updateUserInfo } from "../requests/globalRequest";
import { GlobalContextStore } from "../context/GlobalProvider";
import { gaEnterLoginEmail, gaLoginClick, gaLoginFail, gaSignUpFail } from "../utils/ga";
import { gtmEnterLoginEmail, gtmLoginClick, gtmLoginFail, gtmSignUpFail } from "../utils/gtm";
import { setTokens } from "../utils/localStorage";
import { GetWord } from "../utils/common";

const Container = styled.div`
  box-shadow: 0 3px 15px 0 #c9c9c9;
  background-color: #fff;

  @media ${theme.device.mobile} {
    min-height: 683px;
    width: 314px;
    display: flex;
    flex-direction: column;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 25px;
  }

  @media ${theme.device.desktop} {
    min-height: 580px;
    width: 1024px;
    display: flex;
    flex-direction: row;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
  }
`;

const Left = styled.div`
  flex: 1;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${theme.device.mobile} {
    border-top-right-radius: 25px;
    background-image: url(${ImageLoginBgMobile});
    padding: 40px 20px 30px 20px;
  }

  @media ${theme.device.desktop} {
    border-bottom-left-radius: 50px;
    border-top-right-radius: 0px;
    background-image: url(${ImageLoginBg});
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${theme.device.mobile} {
    flex: 1.5;
    margin: 40px;
  }

  @media ${theme.device.desktop} {
    flex: 1;
    margin: 70px;
  }
`;

const LoginHeader = styled(Typography)`
  && {
    @media ${theme.device.mobile} {
      font-size: ${theme.fontSize.title2}px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    @media ${theme.device.desktop} {
      font-size: ${theme.fontSize.title5}px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
`;

function Login(props) {
  const { onLogin } = props;
  const { t } = useTranslation("login");
  const {globalDispatch} = useContext(GlobalContextStore);

  const navigate = useNavigate();
  const location = useLocation();

  const [step, setStep] = useState(0);
  const [loginEmail, setLoginEmail] = useState();

  const otpFormRef = useRef(null);

  const requestOTPMutation = useMutation({
    mutationFn: ({email}) => requestLoginOtp({ email }),
    onSuccess: (res, {email, isResent=false}) => {
      if (res && res.msg === "success") {
        if (res.contactUs === true) {
          gaSignUpFail();
          gtmSignUpFail();
          return navigate("/contactUs", { replace: true });
        }
        setStep(1);
        setLoginEmail(email);
        if (isResent && otpFormRef?.current) {
          otpFormRef.current.setErrors({ oneTimePassword: "" });
          otpFormRef.current?.setResendOtpCooldown(60);
        }
      }
    },
    onError: (e) => {
      console.log("request otp error - ", e);
      appearGlobalError(
        globalDispatch,
        e?.result?.code,
        e?.result?.errorMessage,
        { type: "fail", }
      );
    }
  });

  const verifyOTPMutation = useMutation({
    mutationFn: (otp) => otpLogin({ email: loginEmail, otp }),
    onSuccess: async (res) => {
      if (res && res.msg === "success") {
        setTokens(res.data.accessToken, res.data.refreshToken);
        await updateUserInfo(globalDispatch);

        navigate({
          pathname: location?.state?.from?.pathname || "/",
          state: { from: "/login" },
          search: location?.state?.from?.search || "",
        })
      }
    },
    onError: (e) => {
      console.log("login error - ", e);
      if (otpFormRef?.current && ["OTP_EXPIRED", "INVALID_OTP"].includes(e?.result?.code) && e?.result?.errorMessage) {
        otpFormRef.current.setErrors({ oneTimePassword: e?.result?.errorMessage });
      } else {
        appearGlobalError(globalDispatch, e?.result?.code, e?.result?.errorMessage, {
          type: "fail",
        });
      }

      gaLoginFail();
      gtmLoginFail();
    }
  })

  const handleRequestOTP = (email, isResent=false) => {
    gaEnterLoginEmail();
    gtmEnterLoginEmail();
    
    requestOTPMutation.mutate({ email, isResent });
  };

  const handleVerifyOTP = (payload) => {
    gaLoginClick();
    gtmLoginClick();
    
    verifyOTPMutation.mutate(payload.oneTimePassword);
  }

  const handleResentOTP = () => {
    handleRequestOTP(loginEmail, true);
  };

  return (
    <Container>
      <Left>
        <Typography
          fontSize={theme.fontSize.header}
          style={{ marginBottom: 12 }}
          bold
          textShadow={"0 3px 6px #c7b645"}
        >
          {t("welcome")}
        </Typography>
        <Typography
          fontSize={theme.fontSize.title4}
          breakSpaces
          align="center"
          bold
          textShadow={"0 3px 6px #c7b645"}
          style={{ maxWidth: 320 }}
          textAlign="center"
        >
          {t("welcome_desc")}
        </Typography>
      </Left>
      <Content>
        <LoginHeader>{t("loginOrCreateAcc")}</LoginHeader>
        {step === 0 ? (
          <CheckEmail
            handleLogin={handleRequestOTP}
            isLoading={requestOTPMutation.isLoading}
          />
        ) : (
          <LoginOTP 
            ref={otpFormRef}
            onResend={handleResentOTP}
            onSubmit={handleVerifyOTP}
            isLoading={verifyOTPMutation.isLoading || requestOTPMutation.isLoading}
          />
        )}
      </Content>
    </Container>
  );
}

export default Login;
