import { useState } from 'react';
import Typography from "../../../components/Typography";
import OrderStatusTag from "./OrderStatusTag";
import { Grid } from "@mui/material";
import theme from '../../../theme';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { breakpoint, WindowSize } from '../../../utils/responsive';
import MyButton from '../../../components/Button';
import Space from '../../../components/Space';
import { isValidForPayAgain } from '../../../utils/common';
import { TitleInfoField } from './CollaspeTitleProp';
import { ReactComponent as PrintIcon } from "../../../assets/ic-print.svg";

const DetailButton = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
`;

const FieldContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;

    // @media ${breakpoint.mobile} {
    //     flex-wrap: wrap;
    // }
`

const FieldWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    
    @media ${breakpoint.mobile} {
        flex-wrap: wrap;
    }
`

const OrderIdStatus = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;

    @media ${breakpoint.mobile} {
        flex-wrap: wrap;
    }
`

const OrderStatusField = (props) => {
    const { 
        orderRecord, 
        label="", 
        value,
        status,
        onClickPayAgain,
        onClickPrint,
    } = props;
    
    const { t } = useTranslation("common");
    
    const isMobile = WindowSize() === 'mobile';

    return (
        <FieldContainer>
            <div style={{ width: 'max-content', textWrap: 'nowrap' }}><Typography fontSize={isMobile ? 18 : 16}>{label}</Typography></div>
            <FieldWrapper>
                <OrderIdStatus>
                    <Grid item><Typography fontSize="18" bold style={{ lineHeight: '24px' }}>{value || orderRecord?.[0].id || ""}</Typography></Grid>
                    { !isMobile && <Grid item><OrderStatusTag status={status || orderRecord?.[0].status || ""} /></Grid> }
                </OrderIdStatus>
            </FieldWrapper>
            {(isValidForPayAgain(status) && !isMobile) && (
                <MyButton
                    size="medium"
                    width="fit-content"
                    fontSize={theme.fontSize.textButton}
                    onClick={e => onClickPayAgain(e)}
                >
                    {t("common:payAgain")}
                </MyButton>
            )}
            {onClickPrint && (
                <TitleInfoField onClick={onClickPrint} style={{ marginTop: isMobile ? '10px' : 0 }}>
                    <PrintIcon />
                    <Typography fontSize={20} color={"#db7600"} bold underline style={{ width: "max-content" }} pointer>
                        {t('profile:orderDetails.print')}
                    </Typography>
                </TitleInfoField>
            )}
        </FieldContainer>
    )
}

  
export default OrderStatusField;