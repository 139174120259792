import { useState } from "react";
import { useTranslation } from "react-i18next";
import GreetingDialog from "./GreetingDialog";
import PlanBenefit from "./PlanBenefit";
import PlanList from "./PlanList";
import Banner from "../../components/Banner";
import LoadingIndicator from "../../components/LoadingIndicator";
import { gaSelectPromotion } from "../../utils/ga";
import { gtmSelectPromotion } from "../../utils/gtm";

const HomeContent = (props) => {
    const { bannerData, companyId=undefined } = props;

    const { i18n } = useTranslation();
    const [isInitLoaded, setIsInitLoaded] = useState(false);

    const handleClickBanner = (item) => {
        const gaPayload = {
            promotion_id: item?._id || "NA",
            destination_page_url: item?.urlPath?.[i18n.language] || "NA"
        };
        gaSelectPromotion(gaPayload);
        gtmSelectPromotion(gaPayload);
    }

    return (
        <div style={{ overflow: "hidden" }}>
            { !companyId && <GreetingDialog /> }
            <Banner
                // FIXME: banner data type
                data={bannerData?.data?.data?.filter(item => item?.animationType === 'static')}
                onClickBanner={handleClickBanner}
                lang={i18n.language}
                onInitLoaded={() => setIsInitLoaded(true)}
                isInitLoaded={isInitLoaded}
            />
            { isInitLoaded ? (
                <>
                    <PlanBenefit />
                    {/* <CompanyRegister /> */}
                    <PlanList companyId={companyId} />
                </>
            ) : (<LoadingIndicator />)}
        </div>
    )
}

export default HomeContent;