import MyTypography from '../../../components/Typography';
import Space from '../../../components/Space';
import { Divider } from '@mui/material';
import theme from '../../../theme';
import SwitchButton from '../../../components/SwitchButton';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import DetailButton from '../../../components/Buttons/DetailButton';
import CardWrapper from '../../../components/Card/CardWrapper';
import { GetWord } from '../../../utils/common';
import _ from 'lodash';
import { ResponsiveUI, breakpoint } from '../../../utils/responsive';
import { WindowSize } from '../../../utils/responsive';
import Checkbox from '../../../components/Common/CommonCheckbox';

const TNCItem = ({
  title,
  shortDescription,
  detail,
  buttonLabel,
  id,
  keyPath,
  acceptedTNC,
  setAcceptedTNC,
  disableDivider,
  detailsTitle = null,
  error,
  helperText
}) => {
  const { t } = useTranslation('common');

  const isChecked = () => {
    const current = _.find(acceptedTNC?.[keyPath], ['tncId', id]);

    return current?.accepted || false;
  };

  const handleClick = (id, keyPath) => {
    const key = keyPath.replace('tnc.', '');
    let temp = acceptedTNC[key];
    const current = _.find(temp, ['tncId', id]);

    if (current) {
      temp = temp.map((item) => {
        if (item.tncId === id) {
          return { tncId: id, accepted: !item.accepted };
        } else {
          return item;
        }
      });
    } else {
      temp.push({ tncId: id, accepted: true });
    }

    setAcceptedTNC((prev) => ({ ...prev, [key]: temp }));
  };

  return (
    <Wrapper>
      <Container>
        {title && (
          <div style={{ marginBottom: 20 }}>
            <MyTypography fontSize="20" bold>
              {GetWord(title)}
            </MyTypography>
          </div>
        )}
        {shortDescription && <TrimText>{GetWord(shortDescription)}</TrimText>}
      </Container>
      <ItemFooter>
        <Checkbox
          id={id}
          label={buttonLabel ? GetWord(buttonLabel) : t('agree')}
          onClick={() => handleClick(id, keyPath)}
          checked={isChecked()}
          checkboxStyle={{ paddingTop: 0, paddingLeft: 0 }}
          typographyProps={{
            style: { fontWeight: 'normal', alignSelf: 'start', wordBreak: "break-word" }
          }}
          style={{ alignItems: 'flex-start', }}
        />
        {detail && (
          <DetailButton title={detailsTitle || title} content={detail} />
        )}
      </ItemFooter>
      {error && <MyTypography fontSize={12} color={theme.error2} style={{ marginTop: 10 }}>
        {helperText || (typeof error === 'string' && error)}
      </MyTypography>}
      {!disableDivider && <Divider style={{ marginTop: 32 }} />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 30px;

  @media ${breakpoint.mobile} {
    padding: 30px 25px;
  }
`;

const ItemFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;
  flex-wrap: nowrap;
`;

const TrimText = styled(MyTypography)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 15px;
`;

const Container = styled.div`
  padding: 0 0 30px 0;

  @media ${breakpoint.mobile} {
    padding: 0px 10px 10px 0;
  }
`;

export default TNCItem;
