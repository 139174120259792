import { forwardRef, useContext, useEffect, useState } from "react";
import { useMutation } from '@tanstack/react-query';
import _ from "lodash";
import styled from "styled-components";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import CustomerInformation from "./form/CustomerInformation";
import DeliveryContactInformation from "./form/DeliveryContactInformation";
import PaymentMethod from "./form/PaymentMethod";
import PersonalInformationStatement from "./form/PersonalInformationStatement";
import SupportingDoc from "./form/SupportingDoc";
import UserInfoForm from "./form/UserInfoForm";
import { formatQuoteProductForGA } from '../../utils/common';
import { gtmAddShippingInfo } from '../../utils/gtm';
import { gaAddShippingInfo } from '../../utils/ga';

import { updateQuote } from '../../apis';
import { appearGlobalError } from '../../requests/globalRequest';
import { GlobalContextStore } from '../../context/GlobalProvider';
import { CheckoutFormContext } from "./context/CheckoutFormContext";

const TNC_KEYPATH = [
  "tnc.savePersonalInformation",
  "tnc.directSaleThisServices",
  "tnc.directSaleMyClubHKT",
];

// IDK should it be prefilled again to all false after starting the new section
const prefillTnc = (data, acceptedTNC, setAcceptedTNC) => {
  data?.map((item) => {
    if (TNC_KEYPATH.includes(item.keyPath)) {
      const key = item.keyPath.replace("tnc.", "");
      let temp = acceptedTNC[key];

      if (_.isEmpty(temp) && !_.isEmpty(item?.value)) {
        temp.push({ tncId: item.value._id, accepted: false });
        setAcceptedTNC((prev) => ({ ...prev, [key]: temp }));
      }

    }
  });
};

const UserInfo = forwardRef(({
  step,
  setStep,
  currentStep,
  viewOnly,
  data,
  tncData,
  configData,
  calendarData,
  refetch,
  acceptedTNC,
  setAcceptedTNC,
  onNext,
}, ref) => {

  const { globalDispatch } = useContext(GlobalContextStore);
  const { isDeviceOnly, hasDevice } = useContext(CheckoutFormContext)
  const [skipValidate, setSkipValidate] = useState(false);

  // useEffect(() => {
  //   if (tncData) {
  //     prefillTnc(tncData, acceptedTNC, setAcceptedTNC);
  //   }
  // }, []);
  

  const updateQuoteMutation = useMutation({
    mutationFn: (payload) => updateQuote(payload),
    onSuccess: (result) => {
      if (result && result?.msg === "success") {
        refetch && refetch();
        if (skipValidate) {
          setSkipValidate(false);
        } else {
          const gaPayload = (data?.items || [])?.map((item, index) => (
            formatQuoteProductForGA({ ...item, sort: index })
          ));
          gaAddShippingInfo(gaPayload);
          gtmAddShippingInfo(gaPayload);

          onNext && onNext();
        }
      } else {
        throw result?.result;
      }
    },
    onError: (e) => {
      console.log("save userinfo error - ", e);
      appearGlobalError(globalDispatch, e?.code, e?.errorMessage || e?.msg, {
        type: "fail",
      });
    }
  })

  const handleSubmit = (payload) => {
    updateQuoteMutation.mutate(payload);
  }

  const theme = createTheme({
    components: {
      MuiGrid: {
        styleOverrides: {
          root: {
            '&.MuiGrid-item': {
              paddingTop: '36px',
            },
          },
        },
      }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <UserInfoForm
        data={data}
        configData={configData}
        step={step}
        setStep={setStep}
        currentStep={currentStep}
        refetch={refetch}
        skipValidate={skipValidate}
        setSkipValidate={setSkipValidate}
        hasPlan={!isDeviceOnly}
        ref={ref}
        onNext={handleSubmit}
        isLoading={updateQuoteMutation.isLoading}
      >
        <Container>
          <CustomerInformation viewOnly={viewOnly} hasPlan={!isDeviceOnly} />
          <PaymentMethod viewOnly={viewOnly} hasPlan={!isDeviceOnly} />
          <DeliveryContactInformation
            viewOnly={viewOnly}
            setSkipValidate={setSkipValidate}
            configData={configData}
            calendarData={calendarData}
            allowDelivery={data?.isAllowedDelivery}
            allowPickup={data?.isAllowedPickup}
            hasDevice={hasDevice}
          />
          {!isDeviceOnly && <SupportingDoc />}
          <PersonalInformationStatement
            data={tncData}
            acceptedTNC={acceptedTNC}
            setAcceptedTNC={setAcceptedTNC}
          />
        </Container>
      </UserInfoForm>
    </ThemeProvider>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default UserInfo;
