import { useField, useFormikContext } from "formik";
import _ from "lodash";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/Button";
import NumberInput from "../../components/NumberInput";
import StepTitle from "../../components/Plan/StepTitle";
import Typography from "../../components/Typography";
import { breakpoint, WindowSize } from "../../utils/responsive";
import { FieldContainer } from "./styled";

const NumberInputContainer = styled.div`
  border-radius: 25px;
  height: 120px;
  padding: 30px;
  margin: 20px 0px;
  display: flex;
  align-items: center;

  @media ${breakpoint.desktop} {
    flex-direction: row;
    box-shadow: 0 3px 10px 0 #c9c9c9;
    justify-content: space-between;
  }

  @media ${breakpoint.mobile} {
    flex-direction: column;
    box-shadow: 0 0 10px 0 #dbbd00;
    justify-content: space-evenly;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;

  @media ${breakpoint.desktop} {
    margin: 0px 50px;
    margin-right: 100px;
    padding-left: 50px;
  }

  @media ${breakpoint.mobile} {
    margin: 0px 30px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1420px;
  margin-left: 20px;
`;

const AddtoCardButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

const QuantiyField = forwardRef(({ stepId, id, steps, onTitleClick, min, max, isLastStep, isSubmitDisabled=false }, ref) => {
  const currentStep = _.find(steps, ["id", stepId]);
  const { t } = useTranslation(["mobile", "common"]);
  const navigate = useNavigate();
  const isMobile = WindowSize() === "mobile";
  const [field, meta, helpers] = useField(id);
  const { submitForm } = useFormikContext();

  const handleNumberOnChange = (number) => helpers.setValue(number);

  return (
    <FieldContainer ref={ref}>
      <StepTitle
        title={t("quantity")}
        stepId={stepId}
        onClick={onTitleClick}
        currentStep={currentStep}
        isLastStep={isLastStep}
      />
      {currentStep?.isActive && (
        <Container>
          <Wrapper>
            <NumberInputContainer>
              <Typography bold fontSize={20}>{t("chooseNumber")}</Typography>
              <NumberInput value={field.value} onChange={handleNumberOnChange} min={min} max={max} />
            </NumberInputContainer>
            <AddtoCardButton>
              <Button
                color="yellow"
                type="submit"
                style={{ width: isMobile ? "100%" : 200 }}
                onClick={() => submitForm()}
                disabled={isSubmitDisabled}
              >
                {t("common:addToCart")}
              </Button>
            </AddtoCardButton>
          </Wrapper>
        </Container>
      )}
    </FieldContainer>
  );
});

export default QuantiyField;
