import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import theme from "../theme";
import styled from "styled-components";

export default function LoadingIndicator({ height, style, containerStyle }) {
  return (
    <Container height={height} style={{ ...containerStyle }}>
      <CircularProgress style={{ color: theme.yellow, ...style }} />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: ${props => props.height || "70vh"};
  display: flex;
  justify-content: center;
  align-items: center;
`;
