import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../components/Header';
import Space from '../components/Space';
import CheckoutFormProvider from '../pageComponents/checkout/context/CheckoutFormContext';
import TncProvider from '../pageComponents/checkout/context/GlobalTncContext';
import PreviewOrder from '../pageComponents/checkout/previewOrder';
import CheckoutForm from '../pageComponents/checkout/CheckoutForm';
import { useLocation, useNavigate } from 'react-router-dom';

const PageContent = () => {

    const { t } = useTranslation("checkout");

    const [isPreview, setIsPreview] = useState(false);

    const handleCheckoutSubmit = () => {
        setIsPreview(true);

        // smooth scroll to page top
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    
    return (
        <>
        {/* Checkout Page Header */}
        <PageHeader>
            {isPreview ? t('preivewOrder') : t('registerAndPayment')}
        </PageHeader>
        <Space size="30px" />

        { isPreview ? (
            <PreviewOrder viewOnly={isPreview} setIsPreview={setIsPreview} />
        ) : (
            <CheckoutForm onSubmit={handleCheckoutSubmit} />
        )}
        </>
    )
}

const CheckoutPageV2 = () => {

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!location?.state?.enableAccess) {
            navigate("/shoppingcart");
        }
    }, [location]);

    return (
        <CheckoutFormProvider>
            <TncProvider>
                <PageContent />
            </TncProvider>
        </CheckoutFormProvider>
    )
}

export default CheckoutPageV2;