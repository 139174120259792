import AlertDialog from "./AlertDialog";
import { GlobalContextStore } from "../../context/GlobalProvider";
import { useState, useContext } from "react";
import { disappearGlobalError } from "../../requests/globalRequest";
import { useTranslation } from "react-i18next";
import { GetWord } from "../../utils/common";

const GlobalErrorDialog = () => {
  const { globalState, globalDispatch } = useContext(GlobalContextStore);
  const { t } = useTranslation("common");

  const errorDialogOption = globalState.globalError?.options || {};

  const isDismissible = errorDialogOption.isDismissible ?? true;
  const handleCloseGlobalErrorDialog = () => {
    if (isDismissible) {
      errorDialogOption.onClose && errorDialogOption.onClose();
      disappearGlobalError(globalDispatch)
    }
  }

  return (
    <AlertDialog
      isOpen={globalState.globalError != null}
      handleClose={isDismissible ? handleCloseGlobalErrorDialog : null}
      title={globalState.globalError?.title || ""}
      content={typeof globalState.globalError?.message === 'object' ? GetWord(globalState.globalError?.message) : globalState.globalError?.message || ""}
      btnLabel={errorDialogOption.btnLabel ? errorDialogOption.btnLabel : t("ok")}
      titleStyle={{ textAlign: "center", ...errorDialogOption?.titleStyle }}
      type={errorDialogOption.type || "alert"}
      onClick={errorDialogOption.onClick}
    />
  );
};

export default GlobalErrorDialog;
