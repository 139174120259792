import { useEffect } from "react";
import styled from "styled-components";
import { useFormikContext, useField } from "formik";
import { useTranslation } from "react-i18next";

import { Divider, Grid } from "@mui/material";
import CommonCheckbox from "../../../../components/Common/CommonCheckbox";
import Space from "../../../../components/Space";
import NewNumber from "../newNumber";
import ExistingNumber from "../existingNumber";

import { WindowSize, breakpoint } from "../../../../utils/responsive";
import { gaSelectAutoRenewal } from "../../../../utils/ga";
import { gtmSelectAutoRenewal } from "../../../../utils/gtm";

const PlanDetail = ({ name, idx, tncData }) => {
  const { t } = useTranslation("checkout");
  const [field, meta, helpers] = useField(`${name}.order_isAutoRenewal`);
  const { values } = useFormikContext();
  const totalPlanNum = values.order_list[idx].quantity;

  const isMobile = WindowSize() === "mobile"

  useEffect(() => {
    const isAutoRenewal = values.order_list[idx]?.isAutoRenewal;
    if (typeof isAutoRenewal === "boolean") {
      helpers.setValue(isAutoRenewal);
    } else {
      helpers.setValue(false);
    }
  }, []);

  return (
    <Container name={name}>
      <Divider />
      <Content>
        <BlockContainer>
          <Block>
            <NewNumber
              name={`${name}`}
              totalPlanNum={totalPlanNum}
              idx={idx}
              required 
            />
          </Block>
          <div>
            <Divider orientation={!isMobile ? "vertical" : undefined} />
          </div>
          <Block>
            <ExistingNumber
              required
              name={`${name}`}
              totalPlanNum={totalPlanNum}
              idx={idx}
              tncData={tncData}
            />
          </Block>
        </BlockContainer>
      </Content>
      <Divider />
      <Space size="20px" />

      <CommonCheckbox
        label={t("autoRenewal")}
        checked={field.value}
        onChange={(e) => {
          helpers.setValue(e.target.checked);

          if (e.target.checked) {
            gaSelectAutoRenewal();
            gtmSelectAutoRenewal();
          }
        }}
        style={{ paddingLeft: isMobile ? 10 : 40 }}
      />
      <Space size="20px" />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  hegiht: auto;
  margin-top: 40px;
`;

const Content = styled.div`
  display: flex;

  @media ${breakpoint.mobile} {
    flex-direction: column;
  }
`;

const BlockContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media ${breakpoint.mobile} {
    flex-direction: column;
  }
`;

const Block = styled.div`
  flex: 1;
`;

export default PlanDetail;
