import Typography from "../../components/Typography";
import NumberInput from "../../components/NumberInput";
import theme from "../../theme";
import { useField } from "formik";
import styled from "styled-components";
import { breakpoint } from "../../utils/responsive";

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 50px;

    @media ${breakpoint.mobile} {
        justify-content: space-between;
    }
`

const QuantityField = (props) => {
    const { id, minQuantity, maxQuantity, t } = props;

    const [field, meta, helpers] = useField(id);

    const handleNumberOnChange = (number) => helpers.setValue(number);

    return ( 
        <Container>
            <Typography>{t('quantity')}</Typography>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <NumberInput 
                    id={id}
                    value={field.value}
                    onChange={handleNumberOnChange}
                    buttonColor={"#fff"}
                    buttonBgColor={theme.blackGradientColor}
                    min={minQuantity && minQuantity > 0 ? minQuantity : undefined}
                    max={maxQuantity && maxQuantity > 0 ? maxQuantity : undefined}
                />
                { meta.error && (
                    <Typography color={theme.error2} fontSize="14" style={{ marginTop: "10px", alignSelf: "center" }}>
                        {t(meta.error)}
                    </Typography>
                )}
            </div>
        </Container>
    )
}

export default QuantityField;